import { GameEvaluations } from "../compute/EngineObjects";

const initialState: GameEvaluations[] = []

const evaluationsReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case "SETEVALS":
            return { ...state, gameEvals: action.payload };
        default:
            return state;
    }
}

export default evaluationsReducer;