import styled from "styled-components";
import { Link } from "react-router-dom";

export const SignInContainer = styled.div`
background: linear-gradient(to top, #000 0%, #1dd3b0 100%);
	/* background: linear-gradient(to top, #2185d0 0%, #1dd3b0 78%); */
	/* clip-path: polygon(0 0, 100% 0, 100% 42%, 0 82%); */
	/* background: #031926; */
	width: 100vw;
	height: 100vh;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
`;

export const NavContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 55;
`;

export const SignInForm = styled.form`
	background: #fff;
	display: flex;
	flex-direction: column;
	height: 50%;
	width: 40%;
	border-radius: 5px;
	box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
	z-index: 50;

	@media screen and (max-width: 750px) {
		height: 50%;
		width: 50%;
	}
	@media screen and (max-width: 420px) {
		height: 80%;
		width: 70%;
	}
`;

export const SignInHeader = styled.h1`
	padding-top: 30px;
	font-size: 2.7rem;
	align-items: center;
	align-self: center;
	font-weight: bold;
	color: #000000;
`;

export const InputWrapper = styled.div`
	width: 60%;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
`;

export const EmailInput = styled.input`
	width: 100%;
	padding: 5px 0 5px 1px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 0;
	outline: 0;
	background: transparent;
	border-bottom: 1px solid black;

	@media screen and (max-width: 540px) {
		margin-top: 8px;
		padding: 7px 0 7px 1px;
	}
	@media screen and (max-width: 410px) {
		margin-top: 6px;
		padding: 5px 0 5px 1px;
	}
	@media screen and (max-width: 360px) {
		margin-top: 3px;
		padding: 3px 0 3px 1px;
	}
`;

export const PasswordInput = styled.input`
	width: 100%;
	padding: 25px 0 5px 1px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 0;
	outline: 0;
	background: transparent;
	border-bottom: 1px solid black;
	type: text;
	-webkit-text-security: disc;
	text-security: disc;

	@media screen and (max-width: 540px) {
		margin-top: 8px;
		padding: 3px 0 3px 1px;
	}
	@media screen and (max-width: 410px) {
		margin-top: 6px;
		padding: 3px 0 3px 1px;
	}
	@media screen and (max-width: 360px) {
		margin-top: 3px;
		padding: 3px 0 3px 1px;
	}
`;
export const ConfirmPasswordInput = styled.input`
	width: 100%;
	padding: 25px 0 5px 1px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 0;
	outline: 0;
	background: transparent;
	border-bottom: 1px solid black;
	type: text;
	-webkit-text-security: disc;
	text-security: disc;

	@media screen and (max-width: 540px) {
		margin-top: 8px;
		padding: 3px 0 3px 1px;
	}
	@media screen and (max-width: 410px) {
		margin-top: 6px;
		padding: 3px 0 3px 1px;
	}
	@media screen and (max-width: 360px) {
		margin-top: 3px;
		padding: 3px 0 3px 1px;
	}
	
`;
export const LichessInput = styled.input`
	width: 100%;
	padding: 25px 0 5px 1px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 0;
	outline: 0;
	background: transparent;
	border-bottom: 1px solid black;

	@media screen and (max-width: 540px) {
		margin-top: 8px;
		padding: 3px 0 3px 1px;
	}
	@media screen and (max-width: 410px) {
		margin-top: 6px;
		padding: 3px 0 3px 1px;
	}
	@media screen and (max-width: 360px) {
		margin-top: 3px;
		padding: 3px 0 3px 1px;
	}
`;
export const ChessInput = styled.input`
	width: 100%;
	padding: 25px 0 5px 1px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 0;
	outline: 0;
	background: transparent;
	border-bottom: 1px solid black;

	@media screen and (max-width: 540px) {
		margin-top: 8px;
		padding: 3px 0 3px 1px;
	}
	@media screen and (max-width: 410px) {
		margin-top: 6px;
		padding: 3px 0 3px 1px;
	}
	@media screen and (max-width: 360px) {
		margin-top: 3px;
		padding: 3px 0 3px 1px;
	}
`;

export const ButtonWrapper = styled.div`
	align-self: center;
	margin-top: 10px;
`;
export const AccountWrapper = styled.div`
	margin-top: 20px;
	padding-bottom: 10px;
	display: inline-block;
	@media screen and (max-width: 360px) {
		flex-direction: column;
	}
`;

export const AccountLabel = styled.p`
	text-align: center;
`;
export const AccountLink = styled(Link)`
	margin-left: 47%;
	text-decoration: none;
	color: #2185d0;
	
	@media screen and (max-width: 750px) {
		margin-left: 45%
	}
`;

export const SignUpError = styled.p`
	padding: 10px 15% 0px 20%;
	text-decoration: none;
	color: #FF0000;
	
	@media screen and (max-width: 750px) {
		margin-left: 45%
	}
`;
