import { GameData } from '../api/ChessWebsiteGameFetchers/GamesFetcher';

const initialState: GameData[] = []

const gamesReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "SET_GAMES":
        return action.payload as GameData[];
      default:
        return state;
    }
  };
  
  export default gamesReducer;