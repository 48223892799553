import React, {useContext, useState} from "react";
import { connect } from 'react-redux';
import { Dispatch } from "redux";
//components
import { Button, LinkButton } from "../../components/Button/ButtonElements";
import Navbar from "../../components/Navbar/index";


//elements
import {
	SignInContainer,
	NavContainer,
	SignInForm,
	SignInHeader,
	InputWrapper,
	EmailInput,
	PasswordInput,
	VisibilityIcon,
	CheckboxWrapper,
	Checkbox,
	KeepMeSignedIn,
	BottomWrapper,
	LinksWrapper,
	ForgotUsername,
	ForgotPassword,
	ButtonWrapper,
} from "./SignInElements";

import UserPool from "../../auth/UserPool";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";

interface SignInPageState {
	email: string,
	password: string,
	passwordVisible: boolean,
}


type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ListContainerProps = StateProps & DispatchProps

class SignInPage extends React.Component<ListContainerProps, SignInPageState> {

	constructor(props: ListContainerProps) {
		super(props);
		this.state = {
			email: '',
			password: '',
			passwordVisible: false,
		}

		this.signIn = this.signIn.bind(this);
		this.handleEmailChanged = this.handleEmailChanged.bind(this);
		this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
		this.handleShowPassword = this.handleShowPassword.bind(this);
	}

	async handleEmailChanged(e: React.FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;
        this.setState({
            email: newValue
        });
    }

    async handlePasswordChanged(e: React.FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;
        this.setState({
            password: newValue
			
        });

    }

	async handleShowPassword() {
		this.setState({
			passwordVisible: !this.state.passwordVisible
		}
		)
	}

	signIn(e: any) {
		e.preventDefault();

		const user = new CognitoUser({
			Username: this.state.email,
			Pool: UserPool
		});

		const authDetails = new AuthenticationDetails({
			Username: this.state.email,
			Password: this.state.password,
		})

		user.authenticateUser(authDetails, {
			onSuccess: (data) => {
				const win: Window = window;
				console.log("success login");
				return win.location = '/dashboard';
			},
			onFailure: (data) => {
				console.error("failed to login: "+ data);
			}
		})
		
	}
	
	render() {
		return (
			<>
				<SignInContainer>
					<NavContainer>
						<Navbar />
					</NavContainer>
					<SignInForm>
						<SignInHeader>Sign In</SignInHeader>
						<InputWrapper>
							<EmailInput placeholder="Email" value={this.state.email} onChange={this.handleEmailChanged} />
							<PasswordInput className="Password" placeholder="Password" id="setPass" value={this.state.password} onChange={(this.handlePasswordChanged)} isVisible={(this.state.passwordVisible)} />
							<VisibilityIcon onClick={(this.handleShowPassword)}/>
						</InputWrapper>
						<CheckboxWrapper>
							<Checkbox type="checkbox" />
							<KeepMeSignedIn>Keep me signed in.</KeepMeSignedIn>
						</CheckboxWrapper>
						<BottomWrapper>
							<LinksWrapper>
								<ForgotUsername
									href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO"
									target="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO"
								>
									Forgot your username?
								</ForgotUsername>
								<ForgotPassword
									href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO"
									target="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstleyVEVO"
								>
									Forgot your password?
								</ForgotPassword>
							</LinksWrapper>
							<ButtonWrapper>
								<Button onClick={this.signIn} primary={true} fontBig={false} big={true}>
									Sign in
								</Button>
							</ButtonWrapper>
						</BottomWrapper>
					</SignInForm>
				</SignInContainer>
			</>
		);
	};

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {}
  };
  
  function mapStateToProps(state: any) {
    return { }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SignInPage)