import { ChessWebsite } from "../pages/trainer/ChessTrainer";


export interface User {
  username?: string,
  chessSite?: ChessWebsite
}
const initialState: User = {
    username: undefined,
    chessSite: undefined,
};

const userReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "SET_USER":
        return action.payload as User;
      default:
        return state;
    }
  };
  
  export default userReducer;