import styled from "styled-components";
import { Link } from 'react-router-dom';

interface LinkButtonProps {
    big: boolean,
    primary: boolean,
    fontBig: boolean,
	to: string
}

interface ButtonProps {
    big: boolean,
    primary: boolean,
    fontBig: boolean,
}

export const LinkButton = styled(Link)<LinkButtonProps>`
	border-radius: 30px;
	min-width: 105px;
	border: none;
	//seafoam green: #39fd9b
	//dank green: 1DD3B0
	padding: ${({ big }: LinkButtonProps) => (big ? "14px 60px" : "12px 30px")};
	background: ${({ primary }: LinkButtonProps) => (primary ? "#2185D0" : "#1DD3B0")};
	color: #ffffff;
	white-space: nowrap;
	font-size: ${({ fontBig }: LinkButtonProps) => (fontBig ? "20px" : "12px")};
	font-family: "Lato", sans-serif;
	font-weight: bold;
	cursor: pointer;
	display: flex;

	align-items: center;
	transition: all 0.2s ease-in-out;
	box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
	text-decoration: none;

	&:hover {
		transition: all 0.2s ease-in-out;
		transform: scale(1.1);
		background: ${({ primary }: LinkButtonProps) => (primary ? "#1DD3B0" : "#fff")};
		color: #000;
	}
`;

export const Button = styled("button")<ButtonProps>`
	border-radius: 30px;
	min-width: 105px;
	border: none;
	//seafoam green: #39fd9b
	//dank green: 1DD3B0
	padding: ${({ big }: ButtonProps) => (big ? "14px 60px" : "12px 30px")};
	background: ${({ primary }: ButtonProps) => (primary ? "#1DD3B0" : "#fff")};
	color: #ffffff;
	white-space: nowrap;
	font-size: ${({ fontBig }: ButtonProps) => (fontBig ? "20px" : "15px")};
	font-family: "Lato", sans-serif;
	font-weight: bold;
	cursor: pointer;
	display: flex;

	align-items: center;
	transition: all 0.2s ease-in-out;
	box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
	text-decoration: none;
	margin-bottom: 10px;

	&:hover {
		transition: all 0.2s ease-in-out;
		transform: scale(1.1);
		background: ${({ primary }: ButtonProps) => (primary ? "#000" : "#1DD3B0")};
		color: #fff;
	}
`;

export const LogOutButton = styled("button")<ButtonProps>`
	border-radius: 10px;
	min-width: 105px;
	border: none;
	//seafoam green: #39fd9b
	//dank green: 1DD3B0
	padding: ${({ big }: ButtonProps) => (big ? "10px 30px" : "12px 30px")};
	background: ${({ primary }: ButtonProps) => (primary ? "#1DD3B0" : "#fff")};
	color: #ffffff;
	white-space: nowrap;
	font-size: ${({ fontBig }: ButtonProps) => (fontBig ? "20px" : "15px")};
	font-family: "Lato", sans-serif;
	font-weight: bold;
	cursor: pointer;
	display: flex;

	align-items: center;
	transition: all 0.2s ease-in-out;
	box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
	text-decoration: none;
	margin-bottom: 2px;

	&:hover {
		transition: all 0.2s ease-in-out;
		transform: scale(1.1);
		background: ${({ primary }: ButtonProps) => (primary ? "#fff" : "#1DD3B0")};
		color: #000;
	}
`;

export const SignInButton = styled("button")<ButtonProps>`
	border-radius: 10px;
	min-width: 105px;
	border: none;
	//seafoam green: #39fd9b
	//dank green: 1DD3B0
	padding: ${({ big }: ButtonProps) => (big ? "10px 30px" : "12px 30px")};
	background: ${({ primary }: ButtonProps) => (primary ? "#1DD3B0" : "#fff")};
	color: #ffffff;
	white-space: nowrap;
	font-size: ${({ fontBig }: ButtonProps) => (fontBig ? "20px" : "15px")};
	font-family: "Lato", sans-serif;
	font-weight: bold;
	cursor: pointer;
	display: flex;

	align-items: center;
	transition: all 0.2s ease-in-out;
	box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
	text-decoration: none;
	margin-bottom: 2px;
	margin-right: 10px;

	&:hover {
		transition: all 0.2s ease-in-out;
		transform: scale(1.1);
		background: ${({ primary }: ButtonProps) => (primary ? "#fff" : "#1DD3B0")};
		color: #000;
	}
`;

export const SignUpButton = styled("button")<ButtonProps>`
	border-radius: 10px;
	min-width: 105px;
	border: none;
	//seafoam green: #39fd9b
	//dank green: 1DD3B0
	padding: ${({ big }: ButtonProps) => (big ? "10px 30px" : "12px 30px")};
	background: ${({ primary }: ButtonProps) => (primary ? "#1DD3B0" : "#fff")};
	color: #ffffff;
	white-space: nowrap;
	font-size: ${({ fontBig }: ButtonProps) => (fontBig ? "20px" : "15px")};
	font-family: "Lato", sans-serif;
	font-weight: bold;
	cursor: pointer;
	display: flex;

	align-items: center;
	transition: all 0.2s ease-in-out;
	box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
	text-decoration: none;
	margin-bottom: 2px;

	&:hover {
		transition: all 0.2s ease-in-out;
		transform: scale(1.1);
		background: ${({ primary }: ButtonProps) => (primary ? "#fff" : "#1DD3B0")};
		color: #000;
	}
`;
