import styled from "styled-components";


export const ChessTrainerContainer = styled.div`
	/* background-color: #6373b5; */
	background: #000000;
	width: 100vw;
	height: 100vh;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
`;

export const ChessTrainerBackground = styled.div`
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: absolute;
`;