import axios from 'axios';
import {AxiosResponse} from 'axios';
import {GameData, GamesFetcher} from './GamesFetcher';


export interface ChessComGameData {
    pgn: string;
    time_control: string;
    rules: string;
    white: {
        username: string;
        rating: number;
    };
    black: {
        username: string;
        rating: number;
    };
    url: string;
}

//docs: https://www.chess.com/news/view/published-data-api
class ChessComGamesFetcher implements GamesFetcher {

    async fetchGames(username: string, numGames: number): Promise<GameData[]> {
        const apiAns = await this.getChessComGames(username, numGames);
        return this.parseChessComGames(apiAns);
    }

    async getAxiosResponse(endpointUrl: string): Promise<AxiosResponse> {
        let answer: AxiosResponse;
        try{
            answer = await axios.get(endpointUrl);
        } catch (err){
            throw new Error("unable to query endpointUrl: "+ endpointUrl);
        }

        return answer;
    }

    async getUserArchives(username: string): Promise<string[]> {
        
        const urlEndpoint: string = `https://api.chess.com/pub/player/${username}/games/archives`;
        let answer: AxiosResponse = await this.getAxiosResponse(urlEndpoint);
        
        return answer.data.archives;
    }

    async getChessComGames(username: string, numGames: number): Promise<ChessComGameData[]> {

        const monthlyArchives: string[] = await this.getUserArchives(username);
        monthlyArchives.sort().reverse();
        
        let allGamesAnswer: ChessComGameData[] = [];

        let counter: number = 0;
        while (allGamesAnswer.length < numGames && counter < monthlyArchives.length) {
            const tempAnswer: ChessComGameData[] = (await this.getAxiosResponse(monthlyArchives[counter])).data.games.reverse();
            allGamesAnswer= allGamesAnswer.concat(this.filterChessRulesGames(tempAnswer));
            counter++;
        }
    
        return allGamesAnswer.slice(0, numGames);
    }

    filterChessRulesGames(games: ChessComGameData[]): ChessComGameData[] {
        return games.filter(game => game.rules.trim() === "chess");
    }

    parseChessComGames(games: ChessComGameData[]): GameData[] {
        return games.map(game => ({
            pgn: game.pgn,
            timeControl: game.time_control,
            rules: game.rules,
            whitePlayer: {username: game.white.username, rating: game.white.rating},
            blackPlayer: {username: game.black.username, rating: game.black.rating},
            url: game.url
        }));
    }
    
}

export default ChessComGamesFetcher;