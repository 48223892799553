import styled from "styled-components";
import { NavLink as LinkR } from "react-router-dom";
import { FaBars } from "react-icons/fa";

export const Nav = styled.nav`
	/* background: #000002; */
	background: #000;
	height: 80px;
	width: 100vw;
	display: flex;
	justify-content: space-between;
	z-index: 10;
`;

export const NavLink = styled(LinkR)`
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0 1rem;
	cursor: pointer;
`;

export const MenuBars = styled(FaBars)`
	display: none;
	color: #2185d0;

	@media screen and (max-width: 768px) {
		display: block;
		position: absolute;
		height: 40px;
		width: 40px;
		margin: 20px 10px 0 0;
		top: 0;
		right: 0;
		transform: translate(-100% 75%);
		font-size: 1.8rem;
		cursor: pointer;
	}
`;
export const NavMenu = styled.div`
	display: flex;
	justify-content: right;
	align-items: center;
	margin-right: 15px;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export const NavButton = styled.nav`
	display: flex;
	align-items: center;
	margin-left: 24px;
	margin-right: 4px;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;


export const LogOutButton = styled.button `
	display: flex;
	align-items: center;
	margin-left: 24px;
	margin-right: 4px;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;