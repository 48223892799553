import * as React from 'react';
import Navbar from '../../components/Navbar';
import IntroModal from '../../components/trainer/IntroModal';
import ChessBoardDiv  from '../../components/chessboard/ChessBoardDiv';
import { NavContainer } from '../dashboard/DashboardElements';

import { connect } from 'react-redux';
import {
    ChessTrainerContainer
} from './ChessTrainerElements';
import { GameData } from '../../api/ChessWebsiteGameFetchers/GamesFetcher';
import Engine from '../../compute/Engine';
import MistakesGenerator from '../../compute/MistakesGenerator';
import PuzzleCreator from '../../compute/PuzzleCreator';
import AnalysisLoadingBar from '../../components/LoadingModal/LoadingModal';
import TrainingCompleteModal from '../../components/TrainingCompleteModal';

export enum ChessWebsite {
    "LICHESS" = "lichess.org",
    "CHESSCOM" = "chess.com"
}

interface TrainerState {
    username: string;
    showIntroModal: boolean;
    isTraining: boolean;
    games: GameData[]
}

type StateProps = ReturnType<typeof mapStateToProps>

class ChessTrainer extends React.Component<StateProps, TrainerState> {

    render() {
        return (
            <ChessTrainerContainer style={{backgroundColor: '#1a1d23'}}>
                <NavContainer>
                    <Navbar />
                </NavContainer>
                <IntroModal />
                <Engine></Engine>
                <MistakesGenerator></MistakesGenerator>
                <PuzzleCreator></PuzzleCreator>
                <ChessBoardDiv></ChessBoardDiv>

                <AnalysisLoadingBar></AnalysisLoadingBar>
                <TrainingCompleteModal></TrainingCompleteModal>
            </ChessTrainerContainer>
        );
    }

    async logUser() {
        console.log("logging")
    }
}

function mapStateToProps(state: any) {
    return { games: state.games }
}

export default connect(mapStateToProps)(ChessTrainer)

