import React, { MouseEvent } from 'react';
import { ChessWebsite } from '../../pages/trainer/ChessTrainer';

import { StatsFetcher } from '../../api/StatsFetchers/StatsFetcher';
import ChessComStatsFetcher from '../../api/StatsFetchers/ChessComStatsFetcher';
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale, // x axis
    LinearScale, // y axis
    PointElement,
    Legend
} from 'chart.js'
import { max } from 'lodash';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale, 
    PointElement,
    Legend
)

interface ProfilePageState {
	chessWebsite: ChessWebsite;
	userName: string;
    stats: any;
}


//class ProfilePage extends React.Component<any, ProfilePageState>
class ProfilePage extends React.Component<{}, ProfilePageState>{
    
    private static readonly GRAPH_OPTIONS = {
        plugins: {
            legend: true
        },
        scales: {
            y: {
                // min: 10,
                // max: 200

            }
        }
    }

	statsFetcherMap: Map<ChessWebsite, StatsFetcher>;

	constructor(props: any) {
        super(props);

		this.state = {
			chessWebsite: ChessWebsite.CHESSCOM,
			userName: "",
            stats: {},
        }
		this.statsFetcherMap = new Map([
            [ChessWebsite.CHESSCOM, new ChessComStatsFetcher() as StatsFetcher]
        ]);
        
    }
    render () {
        const newList = []
        const newData = []
        for (let i = 0; i < Object.keys(this.state.stats).length; i++) {
            newList.push(this.state.stats.reverse()[i]['date'])
            newData.push(this.state.stats.reverse()[i]['User Rating'])
          }


        const data = {
            labels: newList,
            datasets: [
                {
                    label: 'Last 100 Games',
                    data: newData,
                    backgroundColor: 'aqua',
                    borderColor: 'black',
                    pointBorderColor: 'aqua',
                }
            ]
        }
        return (
        <>
        <div className='App'>
            <h1>Rating Chart</h1>
            <div style= {
                {
                width: '1200px',
                height: '1000px'
                }
            }>
                <div className='Testing'>
                <Line
                data = {data}
                ></Line>
                </div>
            </div>
        </div>

        </>
        )
    }

    async componentDidMount() {
        const statsFetch = new ChessComStatsFetcher();
        const { ratings } = await statsFetch.fetchStats("im_kosta");
        const stats = Array.from(ratings, ([date, userRating]) => ({ date, "User Rating": userRating }));
        this.setState({ stats });
    }
	
}

export default ProfilePage
