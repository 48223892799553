import * as ChessJS from "chess.js";
import { Square} from "chess.js";

export interface MoveMade {
    moveLegal: boolean;
    FEN: string;
    newBoard: string[]; 
}

export enum Colour {
    WHITE = "white",
    BLACK = "black"
}

export function makeMoveFen(fen: string, moveUci: string) : string {
    const Chess = typeof ChessJS === "function" ? ChessJS : ChessJS.Chess; // For VS code intellisence to work
    const game = new Chess(fen);
    const fromSquare: Square = (moveUci.substring(0,2)) as Square;
    const movingtoSquare = moveUci.substring(2, 4)  as Square;


    const moveObject = game.move({from: fromSquare, to: movingtoSquare });

    return game.fen()
} 

export function getColourToMove(theGame: ChessJS.Chess): Colour {
    
    if(theGame.turn() === 'b') {
        return Colour.BLACK
    }

    return Colour.WHITE;
}

export function delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}