import { Route, Navigate, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';

//component imports
//navbar
import Dashboard from "./pages/dashboard/Dashboard";
import ChessTrainer from "./pages/trainer/ChessTrainer";
import SignInPage from './pages/sign-in/SignInPage';
import SignUpPage from './pages/sign-up/SignUpPage';
import ProfilePage from './pages/profile/ProfilePage';
import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'

//login
//sign up

function App() {
  const queryClient = new QueryClient();
  return (
    <>
			<main className="container">
        <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/dashboard" element={<Dashboard/>} />
            <Route path="/trainer" element={<ChessTrainer/>} />
            <Route path="/sign-in" element={<SignInPage/>} />
            <Route path="/sign-up" element={<SignUpPage/>} />
            <Route path="/profile" element={<ProfilePage/>} />

            <Route path="*" element={<Navigate to="/dashboard" replace />}/>

          </Routes>
        </BrowserRouter>
        </QueryClientProvider>
			</main>
		</>
  );
}

export default App;
