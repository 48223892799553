import React from "react";
//components
import { LinkButton } from "../../components/Button/ButtonElements"
import Navbar from "../../components/Navbar/index";
//assets
import Img from "../../images/backgrounds/pieces.jpg";
//elements
import {
	DashboardContainer,
	NavContainer,
	DashboardBackground,
	ImgBG,
	DashboardTextWrapper,
	DashboardHeader,
	DashboardSubHeader,
	BtnWrapper,
} from "./DashboardElements";

const Dashboard = () => {

	return (
		<>
			<DashboardContainer>
				<NavContainer>
					<Navbar />
				</NavContainer>

				<DashboardBackground>
					<ImgBG src={Img} alt="chess pieces" />
				</DashboardBackground>
				<DashboardTextWrapper>
					<DashboardHeader>TRAIN LIKE AN ENGINE</DashboardHeader>
					<DashboardSubHeader>
						POWERING CHESS TRAINING THROUGH MACHINE LEARNING
					</DashboardSubHeader>
					<BtnWrapper>
						<LinkButton to="/trainer" primary={false} fontBig={true} big={false}>
							START TRAINING SESSION
						</LinkButton>
					</BtnWrapper>
				</DashboardTextWrapper>
			</DashboardContainer>
		</>
	);
};

export default Dashboard;
