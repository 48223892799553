import { connect } from 'react-redux';
import { Dispatch } from "redux";
import React from 'react';

import { GameEvaluations, MistakeObject, MistakeObjectComplete } from './EngineObjects';
import { Player } from '../shared_objects/Player';

interface MistakesGeneratorState {
  gameEvals: GameEvaluations[];
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ListContainerProps = StateProps & DispatchProps

class MistakesGenerator extends React.Component<ListContainerProps, MistakesGeneratorState> {

    private gameEvals = [];
    private mistakeThreshold = 110;

    constructor(props: any) {
        super(props);
    
        this.state = {
          gameEvals: []
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
    
        //POSSIBLE TODO: setState is async.. so cant rlly expect eval to have correct values, its why we update class var
        if (this.props.gameEvals.gameEvals != null && this.props.gameEvals.gameEvals !== this.gameEvals && this.props.gameEvals.gameEvals.length > 0) {
    
          this.gameEvals = this.props.gameEvals.gameEvals;
          this.setMistakes();
        }
    }

    getMistakesFromGame(currentGame: GameEvaluations): MistakeObject[]  {
        let allMistakes: MistakeObject[] = [];
        const differenceThreshold = this.mistakeThreshold;

        let userColour: string = "black";
        if(currentGame.pgnData.whitePlayer === this.props.user.username) {
            userColour = "white";
        }

        let lastEval: number = 0;

        for(let k = 1; k < currentGame.positionEvals.length; k++){
            if(userColour === "white" && k%2 === 1){
                const differenceVal = lastEval -currentGame.positionEvals[k].eval;
                if(differenceVal >= differenceThreshold){
                    allMistakes.push(this.createMistake(currentGame, k, differenceVal));
                }
            }
            else if(userColour === "black" && k%2 === 0){
                const differenceVal = (lastEval -currentGame.positionEvals[k].eval)*-1;
                if(differenceVal >= differenceThreshold){
                    allMistakes.push(this.createMistake(currentGame, k, differenceVal));
                }
            }

            lastEval = currentGame.positionEvals[k].eval;
        }

        return allMistakes;
    }

    createMistake(currentGame: GameEvaluations, moveNumber: number, differenceVal: number): MistakeObject {
        let userColour: string = "black";
        if(currentGame.pgnData.whitePlayer === this.props.user.username) {
            userColour = "white";
        }

        return {
            lastBoardFEN: currentGame.positionEvals[moveNumber-1].position,
            currentBoardFEN: currentGame.positionEvals[moveNumber].position,
            scoreChange: differenceVal.toString(),
            move: currentGame.positionEvals[moveNumber-1].myMove,
            myColour: userColour,
            lastBoardScore: currentGame.positionEvals[moveNumber-1].eval,
            currentBoardScore: currentGame.positionEvals[moveNumber].eval,
            fromGame: currentGame.pgnData,
        }
    }

    getMistakes() {
        let allMistakes: MistakeObject[] = [];
        const differenceThreshold = this.mistakeThreshold;

        for (let i = 0; i < this.gameEvals.length; i++) {
            const currentGame: GameEvaluations = this.gameEvals[i];
            allMistakes.push(...this.getMistakesFromGame(currentGame));
        }

        console.log("got mistakes:");
        console.log(allMistakes);

        return allMistakes;

    }

    setMistakes() {
        this.props.setMistakes(this.getMistakes());
    }

    render() {
        return (null);
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
      setEvaluations: (evals: any[]) => dispatch({type: 'SETEVALS', payload: evals}),
      setMistakes: (mistakes: any[]) => dispatch({type: 'SETMISTAKES', payload: mistakes})
  }
};

function mapStateToProps(state: any) {
  return { gameEvals: state.gameEvals, user: state.user }
}

export default connect(mapStateToProps, mapDispatchToProps)(MistakesGenerator)