import { connect } from 'react-redux';
import { Dispatch } from "redux";
import React from 'react';
import { MistakeObjectComplete } from '../../compute/EngineObjects';
import { PuzzleStage, getAllPuzzleStages } from '../chessboard/ChessBoardDiv';
import { Menu, Header, Icon, Divider } from 'semantic-ui-react'
import { PuzzleResult } from '../../reducers/puzzleResultReducer';
import { restartTrainingInitialState } from '../../reducers/restartTrainingReducer';

interface ScoreBoardState {
  scores: AllPuzzleScores,
  dummyUpdate: number,
}

export enum PuzzleScore {
    NOT_DONE = 0,
    CORRECT = 1,
    INCORRECT = 2
}

export interface StagePuzzleScores {
    mistakeObject: MistakeObjectComplete,
    puzzleScore: PuzzleScore
}

export type AllPuzzleScores = {
    [key in PuzzleStage]: StagePuzzleScores[]
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ScoreBoardProps = StateProps & DispatchProps

class ScoreBoard extends React.Component<ScoreBoardProps, ScoreBoardState> {

    private puzzles: MistakeObjectComplete[] = [];
    private opponentPuzzles: MistakeObjectComplete[] = [];
    private restartTrainingUuid: string = "";

    private lastScoreUpdated: MistakeObjectComplete | undefined;

    constructor(props: any) {
        super(props);
    
        this.state = {
          scores: {
            [PuzzleStage.PLAYER_SIMPLE]: [],
            [PuzzleStage.OPPONENT_SIMPLE]: [],
        },
        dummyUpdate: 1
        }

        this.lastScoreUpdated = undefined;
    }

    clearPuzzleScores() {

        let scores: AllPuzzleScores = {
            [PuzzleStage.PLAYER_SIMPLE]: [],
            [PuzzleStage.OPPONENT_SIMPLE]: [],
        };


        this.setState({
            scores: scores
        }, () => this.setAllScoresMaps());


    }

    setAllScoresMaps() {
        this.setupScoreMap(this.puzzles, PuzzleStage.PLAYER_SIMPLE);
        this.setupScoreMap(this.opponentPuzzles, PuzzleStage.OPPONENT_SIMPLE);
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.props.restartTraining != null && this.props.restartTraining !== this.restartTrainingUuid && this.props.restartTraining !== restartTrainingInitialState) {
            this.restartTrainingUuid = this.props.restartTraining;
            this.clearPuzzleScores();
            this.lastScoreUpdated = undefined;
        }

        if (this.props.puzzles.puzzles != null && this.props.puzzles.puzzles !== this.puzzles && this.props.puzzles.puzzles.length > 0) {
            this.setupScoreMap(this.props.puzzles.puzzles, PuzzleStage.PLAYER_SIMPLE);
            this.puzzles = this.props.puzzles.puzzles;
        }

        else if (this.props.opponentPuzzles.opponentPuzzles != null && this.props.opponentPuzzles.opponentPuzzles !== this.opponentPuzzles && this.props.opponentPuzzles.opponentPuzzles.length > 0) {
            this.opponentPuzzles = this.props.opponentPuzzles.opponentPuzzles;
            this.setupScoreMap(this.props.opponentPuzzles.opponentPuzzles, PuzzleStage.OPPONENT_SIMPLE);
        }

        else if(this.props.thePuzzleResult.puzzle != null && this.shouldUpdatePuzzleScore(this.props.thePuzzleResult.puzzle)) {
            this.setPuzzleScore(this.props.thePuzzleResult);
        }
    }

    shouldUpdatePuzzleScore(puzzle: MistakeObjectComplete) {
        if (this.lastScoreUpdated == null) {
            return true;
        }
        if (this.props.thePuzzleResult.puzzle.lastBoardFEN !== this.lastScoreUpdated!.lastBoardFEN) {
            return true;
        }
        return false;
    }


    setPuzzleScore(puzzleResult: PuzzleResult) {

        let currentScoresState: StagePuzzleScores[] = this.state.scores[puzzleResult.puzzleStage];
        const currentScores: StagePuzzleScores[]  = Object.assign([], currentScoresState);

        for (let i = 0; i < currentScores.length; i++) {
            const element = currentScores[i];
            if(element.mistakeObject === puzzleResult.puzzle! && element.puzzleScore === PuzzleScore.NOT_DONE) {
                //this.state.scores[PuzzleStage.PLAYER_SIMPLE][i].puzzleScore = puzzleResult.result;
                currentScores[i].puzzleScore = puzzleResult.result
                break;
            }
        }

        console.log("set lastScore updated");
        this.lastScoreUpdated = puzzleResult.puzzle;


        this.setState(prevState => ({
            ...prevState,
            scores: {
                ...prevState.scores,
                [puzzleResult.puzzleStage]: currentScores
            }
            //scores[PuzzleStage.PLAYER_SIMPLE]: this.state.scores[PuzzleStage.PLAYER_SIMPLE].map(el => (el.id === id ? Object.assign({}, el, { text }) : el))
            //this.state.data.map(el => (el.id === id ? Object.assign({}, el, { text }) : el))
        }));
        
    }

    setupScoreMap(puzzles: MistakeObjectComplete[], puzzleStage: PuzzleStage) {
        let scoreList: StagePuzzleScores[] = [];

        for (const mistakeObject of puzzles) {
            scoreList.push({mistakeObject, puzzleScore: PuzzleScore.NOT_DONE});
        }

        const currentScores: AllPuzzleScores = this.state.scores;
        currentScores[puzzleStage] = scoreList;

        this.setState({
            dummyUpdate: 2,
            scores: currentScores
        })
    }


    getPuzzleScores() {

        let allScores: any[] = [];

        let counter: number = 0;
        getAllPuzzleStages().forEach(puzzleStage => {
            const mistakesList = this.state.scores[puzzleStage as unknown as PuzzleStage]
            let returnList: React.ReactNode[] = [];

            if (mistakesList != null) {
                mistakesList.forEach(puzzleScore => {
                    returnList.push(this.getStar(puzzleScore.puzzleScore));
                })
            }
            counter++;
            allScores.push(this.getRoundScores(returnList, "Round "+counter.toString()));
        });

        return allScores;
    }


    getStar(score: PuzzleScore): React.ReactNode {
        if(score === PuzzleScore.NOT_DONE){
            return (
                <Icon name='star outline' size={'big'} />
            )
        }
        else if(score === PuzzleScore.CORRECT){
            return (
                <Icon name='star' size={'big'} color={"green"}/>
            )
        }
        else if(score === PuzzleScore.INCORRECT){
            return (
                <Icon name='star' size={'big'} color={"red"}/>
            )
        }
    }

    getRoundScores(roundScores: React.ReactNode, roundName: String): React.ReactNode {
        return (
            <Menu.Item key={(Math.random() + 1).toString(36)}>
                <Divider horizontal>
                    <Header as='h4' content={`${roundName}`}>
                    </Header>
                </Divider>
                <div className="linkToGame" style={{whiteSpace: 'pre-wrap', textAlign: 'center', margin: 'auto'}}>
                {roundScores}
                </div>
            </Menu.Item>
        );
    }

    render(): React.ReactNode {
        return (
            <Menu vertical secondary style={{backgroundColor: 'rgba(70,70,70,.5)', height: '100%', border: '5px solid black', borderRadius: '5px', width: '300px', marginLeft: '6px'}}>
                <Menu.Item >
                    <Header as='h2'>
                    <div className="exclamationHeader" style={{whiteSpace: 'pre-wrap', textAlign: 'center', margin: 'auto'}}>
                        <Icon name='chart line' />
                        <Header.Content style={{color: 'wheat'}}>Scores</Header.Content>
                        </div>
                    </Header>
                </Menu.Item>

                {this.getPuzzleScores()}

            </Menu>
        )
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {}
};

function mapStateToProps(theState: any) {
    return { puzzles: theState.puzzles, thePuzzleResult: theState.thePuzzleResult, opponentPuzzles: theState.opponentPuzzles, restartTraining: theState.restartTraining  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScoreBoard)