import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/userReducer';
import gamesReducer from './reducers/gamesReducer';
import evaluationsReducer from './reducers/evaluationsReducer';
import mistakesReducer from './reducers/mistakesReducer';
import puzzlesReducer from './reducers/puzzlesReducer';
import opponentPuzzlesReducer from './reducers/opponentPuzzleReducer';
import loadingProgressReducer from './reducers/loadingProgressReducer';
import trainingStateReducer from './reducers/trainingStateReducer';
import puzzleResultReducer from './reducers/puzzleResultReducer';
import trainingCompleteReducer from './reducers/trainingCompleteReducer';
import restartTrainingReducer from './reducers/restartTrainingReducer';

const store = configureStore({
    reducer: {
      user: userReducer,
      games: gamesReducer,
      gameEvals: evaluationsReducer,
      mistakes: mistakesReducer,
      puzzles: puzzlesReducer,
      opponentPuzzles: opponentPuzzlesReducer,
      loadingPercentage: loadingProgressReducer,
      trainingState: trainingStateReducer,
      thePuzzleResult: puzzleResultReducer,
      trainingComplete: trainingCompleteReducer,
      restartTraining: restartTrainingReducer
    },
})

export default store;