import React, { CSSProperties} from 'react';
import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { Grid, Header, Modal, Icon, Transition } from 'semantic-ui-react'

import * as ChessJS from "chess.js";


import PuzzleControlsComponent from "./PuzzleControlsComponent";
import MistakeComponent from "./MistakeComponent";

import { Chessboard, CustomSquareStyles } from "react-chessboard";
import { CorrectMove, getDefaultMistakeObjectComplete, MistakeObjectComplete } from '../../compute/EngineObjects';
import { makeMoveFen, delay } from '../../compute/gameplay';
import { Square } from 'kokopu';
import { TrainingState } from '../../reducers/trainingStateReducer';
import ScoreBoard, { PuzzleScore } from '../scoreboard/ScoreBoard';
import { PuzzleResult } from '../../reducers/puzzleResultReducer';
import HelpTipComponent from '../tips/HelpTipComponent';
import { restartTrainingInitialState } from '../../reducers/restartTrainingReducer';
import EvalBar from '../EvalBar/EvalBar';
import PlayerName from '../PlayerName/PlayerName';

let correctMovePlayedAudio = new Audio("sounds/correctMovePlayed.mp3");
correctMovePlayedAudio.volume = 0.4;

let wrongMovePlayedAudio = new Audio("sounds/wrongMovePlayed.mp3");
wrongMovePlayedAudio.volume = 0.4;

interface ChessboardState {
    currentBoardFen: string;
    currentGame: ChessJS.Chess;
    puzzles: MistakeObjectComplete[];
    opponentPuzzles: MistakeObjectComplete[];
    currentPuzzle?: MistakeObjectComplete;
    currentPuzzleNumber: number;
    puzzleStage: PuzzleStage;
    moveAnimation: MoveResult;
    currentArrows: string[][];
    windowDimensions: WindowDimensions;
    customArrowsColour: string;
}

enum MoveResult {
    CORRECT = 0,
    INCORRECT = 1,
    NONE = 2
}

interface WindowDimensions {
    height: number,
    width: number
}

export enum PuzzleStage {
    OPPONENT_SIMPLE,
    PLAYER_SIMPLE
}

export function getAllPuzzleStages(): PuzzleStage[] {
    let outputList: PuzzleStage[] = [];
    for (let item in PuzzleStage) {
        if (!isNaN(Number(item))) {
            outputList.push(item as unknown as PuzzleStage);
        }
    }
    return outputList;
}


export function getNextPuzzleStage(puzzleStage: PuzzleStage): PuzzleStage | undefined {
    switch (puzzleStage) {
        case PuzzleStage.OPPONENT_SIMPLE:
            return PuzzleStage.PLAYER_SIMPLE
        case PuzzleStage.PLAYER_SIMPLE:
            return undefined;
        default:
            return undefined;
    }
}

const Chess = typeof ChessJS === "function" ? ChessJS : ChessJS.Chess;

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ListContainerProps = StateProps & DispatchProps

class ChessBoardDiv extends React.Component<ListContainerProps, ChessboardState> {

    private puzzles: MistakeObjectComplete[] = [];
    private opponentPuzzles: MistakeObjectComplete[] = [];
    private restartTrainingUuid: string = "";

    constructor(props: any) {
        super(props);
        this.state = {
            currentBoardFen: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1",
            puzzles: [],
            opponentPuzzles: [],
            currentGame: new Chess(),
            currentPuzzleNumber: 0,
            puzzleStage: PuzzleStage.OPPONENT_SIMPLE,
            moveAnimation: MoveResult.NONE,
            currentArrows: [],
            windowDimensions: {
                height: window.innerHeight,
                width: window.innerWidth
            },
            customArrowsColour: '#cf7d42',
        }

        this.onPieceDrop = this.onPieceDrop.bind(this);

        this.iteratePuzzleStage = this.iteratePuzzleStage.bind(this);
        this.handleHintButtonClick = this.handleHintButtonClick.bind(this);
        this.handleBackPuzzleClicked = this.handleBackPuzzleClicked.bind(this);
        this.handleNextPuzzleClicked = this.handleNextPuzzleClicked.bind(this);
        this.onPieceDragBegin = this.onPieceDragBegin.bind(this);

        this.handleWindowResize = this.handleWindowResize.bind(this);

        window.addEventListener('resize', this.handleWindowResize);
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        
        if (this.props.opponentPuzzles.opponentPuzzles != null && this.props.opponentPuzzles.opponentPuzzles !== this.opponentPuzzles && this.props.opponentPuzzles.opponentPuzzles.length > 0) {
            console.log("setting opponentPuzzles chessBoarDiv", this.props.opponentPuzzles.opponentPuzzles);
            this.setState({
                opponentPuzzles: this.props.opponentPuzzles.opponentPuzzles
            });
            this.opponentPuzzles = this.props.opponentPuzzles.opponentPuzzles;
        }

        else if (this.props.puzzles.puzzles != null && this.props.puzzles.puzzles !== this.puzzles && this.props.puzzles.puzzles.length > 0) {
            console.log ("Starting Training!");
            this.setState({
                puzzles: this.props.puzzles.puzzles
            });
            this.puzzles = this.props.puzzles.puzzles;
            this.startTraining();
        }
        else if (this.props.restartTraining != null && this.props.restartTraining !== this.restartTrainingUuid && this.props.restartTraining !== restartTrainingInitialState) {
            this.restartTrainingUuid = this.props.restartTraining;
            this.startTraining();
        }

    }

    handleWindowResize() {
        this.setState({
            windowDimensions: {
                width: window.innerWidth,
                height: window.innerHeight
            }
        })
    }
    
    startTraining() {
        this.props.setTrainingState(TrainingState.TRAINING);
        this.setState({
            puzzleStage: PuzzleStage.OPPONENT_SIMPLE
        },  () => this.setUpPuzzleNumber(0));
    }

    setupPlayerSimplePuzzle(puzzleNumber: number) {
        console.log("setupPlayerSimplePuzzle");
        delay(1000).then(any=>{
            this.setState({
                moveAnimation: MoveResult.NONE
            });
        });
        
        if (puzzleNumber >= this.puzzles.length) {
            this.iteratePuzzleStage();
            return;
        }
        const thePuzzle = this.puzzles[puzzleNumber];
        console.log("setting puzzle: ", thePuzzle);
        this.setState({
            currentPuzzleNumber: puzzleNumber,
            currentPuzzle: thePuzzle,
            currentBoardFen: thePuzzle.lastBoardFEN,
            currentGame: new Chess(thePuzzle.lastBoardFEN),
            currentArrows: [[thePuzzle.move.substring(0, 2), thePuzzle.move.substring(2)]],
            customArrowsColour: '#c43131'
        });
    }

    setupOpponentSimplePuzzle(puzzleNumber: number) {
        delay(1000).then(any=>{
            this.setState({
                moveAnimation: MoveResult.NONE
            });
        });
        
        if (puzzleNumber >= this.opponentPuzzles.length) {
            this.iteratePuzzleStage();
            return;
        }
        const thePuzzle = this.opponentPuzzles[puzzleNumber];
        console.log("setting puzzle: ", thePuzzle)
        this.setState({
            currentPuzzleNumber: puzzleNumber,
            currentPuzzle: thePuzzle,
            currentBoardFen: thePuzzle.lastBoardFEN,
            currentGame: new Chess(thePuzzle.lastBoardFEN),
            customArrowsColour: '#c43131',
            currentArrows: [[thePuzzle.move.substring(0, 2), thePuzzle.move.substring(2)]]
        });
    }

    //TODO: setup puzzle number of current puzzle training stage
    //i.e: if its the opponent mistakes turn, show puzzle from opponent perspective
    setUpPuzzleNumber(puzzleNumber: number) {

        switch (this.state.puzzleStage) {
            case PuzzleStage.PLAYER_SIMPLE:
                console.log("Playing my simple stage");
                this.setupPlayerSimplePuzzle(puzzleNumber)
                break;
            case PuzzleStage.OPPONENT_SIMPLE:
                console.log("Playing opponent simple stage");
                this.setupOpponentSimplePuzzle(puzzleNumber);
                break;
            default:
                console.log("setupPuzzle not setup for PuzzleStage");
                break;
        }
    }

    iteratePuzzleStage() {
        //const newStage: PuzzleStage = PuzzleStage.after(this.state.puzzleStage);
        const newStage: PuzzleStage = getNextPuzzleStage(this.state.puzzleStage)!;
        if(newStage === undefined) {
            this.props.setTrainerComplete();
            return;
        }

        this.setState({
            puzzleStage: newStage
        }, () => this.setUpPuzzleNumber(0));

    }

    onPieceDrop(sourceSquare: any, targetSquare: any) {
        const theGame: ChessJS.Chess = new Chess(this.state.currentBoardFen);
        const result = theGame.move({from: sourceSquare, to: targetSquare});
            
        // illegal move
        if (result === null) {
            return false;
        }

        const previousFen = this.state.currentBoardFen;
        this.setState({
            currentBoardFen: makeMoveFen(this.state.currentBoardFen, sourceSquare+targetSquare),
            currentGame: theGame
        });

        //puzzle correct move
        if(this.isMoveCorrect(sourceSquare+targetSquare)) {
            console.log("Move was correct!");
            correctMovePlayedAudio.play();
            this.setState({
                moveAnimation: MoveResult.CORRECT
            });
            const puzzleResult: PuzzleResult = {
                puzzle: this.state.currentPuzzle!,
                result: PuzzleScore.CORRECT,
                puzzleStage: this.state.puzzleStage
            };
            this.props.setPuzzleResult(puzzleResult);
            this.setUpPuzzleNumber(this.state.currentPuzzleNumber+1);
        }
        //puzle wrong move
        else {
            wrongMovePlayedAudio.play();
            console.log("Move was incorrect!", sourceSquare+targetSquare, " correct move is: ", this.state.currentPuzzle!.correctMoves);
            this.setState({
                moveAnimation: MoveResult.INCORRECT
            });
            this.setState({
                currentBoardFen: previousFen,
                currentGame: new Chess(previousFen)
            });
            const puzzleResult: PuzzleResult = {
                puzzle: this.state.currentPuzzle!,
                result: PuzzleScore.INCORRECT,
                puzzleStage: this.state.puzzleStage
            };
            this.props.setPuzzleResult(puzzleResult);

            delay(1000).then(any=>{
                this.setState({
                    moveAnimation: MoveResult.NONE
                });
            });
        }

        return true;
    }

    isMoveCorrect(playedMoved: string): boolean {
        const correctMoves: CorrectMove[] = this.state.currentPuzzle!.correctMoves;
        
        for (const move of correctMoves) {
            if (move.moveUci === playedMoved) {
                return true;
            }
        }

        return false;
    }

    getTransitionModal(){
        if(this.state.moveAnimation === MoveResult.CORRECT){
            return (
                <Transition visible={this.state.moveAnimation === MoveResult.CORRECT} animation='scale' duration={500}>
                    <Modal
                        className="modalCheck"
                        basic
                        size='large'
                        open={this.state.moveAnimation === MoveResult.CORRECT}
                        >
                        <Header icon className="checkboxheader">
                            <Icon name='checkmark' color='green'/>
                        </Header>

                    </Modal>
                </Transition>
            )
        }
        else if (this.state.moveAnimation === MoveResult.INCORRECT){
            return (
                <Transition visible={this.state.moveAnimation === MoveResult.INCORRECT} animation='scale' duration={500}>
                    <Modal
                        basic
                        size='large'
                        open={this.state.moveAnimation === MoveResult.INCORRECT}
                        >
                        <Header icon className="xboxheader">
                            <Icon name='delete' color='red'/>
                        </Header>

                    </Modal>
                </Transition>
            )
        }
        
        return null;
    }

    //in cases when we want to play from opponent side we need this
    //refactor this to be cleaner
    getCurrentBoardColour(): "white" | "black" {
        if (this.state.puzzleStage === PuzzleStage.OPPONENT_SIMPLE) {
            return this.getOpponentPlayerColour();
        } 

        return this.getPuzzlePlayerColour();
    }

    //null/undefined checks: https://stackoverflow.com/questions/28975896/is-there-a-way-to-check-for-both-null-and-undefined
    getPuzzlePlayerColour(): "white" | "black" {
        return (this.state.currentPuzzle == null ? 'white' : this.state.currentPuzzle!.myColour) as "white" | "black"
    }

    getOpponentPlayerColour(): "white" | "black" {
        if (this.getPuzzlePlayerColour() === "white" && this.state.currentPuzzle != null) {
            return "black"
        }

        return "white"
    }

    onPieceDragBegin(piece: any, sourceSquare: any) {
        this.setState({
            customArrowsColour: '#cf7d42',
            currentArrows: []
        });
    }

    getCurrentMistake(): MistakeObjectComplete | undefined {

        switch (this.state.puzzleStage) {
            case PuzzleStage.OPPONENT_SIMPLE:
                return this.state.opponentPuzzles[this.state.currentPuzzleNumber]
            case PuzzleStage.PLAYER_SIMPLE:
                return this.state.puzzles[this.state.currentPuzzleNumber]
            default:
                return undefined;
        }
    }

    getCurrentMistakeOrDefault(): MistakeObjectComplete {
        return this.getCurrentMistake() || getDefaultMistakeObjectComplete();
    }

    getChessSquares(): Square[] {
        let outputList: Square[] = [];
        for(var letter=97;letter<105;letter++) {
            for(var i = 1; i<=8;i++) {
                outputList.push(String.fromCharCode(letter)+i.toString() as Square);
            }
        }

        return outputList;
    }

    getCustomSquareStyles(): CustomSquareStyles {
        let customSquareStyles: {[key in Square]?: CSSProperties} = {};
        for(var theKey of this.getChessSquares()) {
            customSquareStyles[theKey] = this.getSquareStyle();
        }
        return customSquareStyles;
    }

    handleBackPuzzleClicked(event: MouseEvent) {
        const currentPuzzleNumber: number = this.state.currentPuzzleNumber;
        if(currentPuzzleNumber <= 0) {
            console.log("cant go back before 0 puzzle");
            return;
        }

        console.log("next puzzle button clicked");
        
        this.setState({
            currentPuzzleNumber: currentPuzzleNumber-1,
        });

        this.setUpPuzzleNumber(currentPuzzleNumber-1);
    }

    handleNextPuzzleClicked(event: MouseEvent) {
        console.log("next puzzle button clicked");
        const currentPuzzleNumber: number = this.state.currentPuzzleNumber;
        this.setState({
            currentPuzzleNumber: currentPuzzleNumber+1,
        });

        this.setUpPuzzleNumber(currentPuzzleNumber+1);
    }

    handleHintButtonClick(event: MouseEvent) {
        const bestMoveUci: string = this.getBestMove(this.getCurrentMistake()!);
        this.setState({
            customArrowsColour: '#cf7d42', 
            currentArrows: [[bestMoveUci.substring(0, 2),bestMoveUci.substring(2)]]
        });
    }

    getBestMove(aMistake: MistakeObjectComplete): string {
        if((aMistake.myColour === "black" && this.state.puzzleStage === PuzzleStage.PLAYER_SIMPLE) || (aMistake.myColour === "white" && this.state.puzzleStage === PuzzleStage.OPPONENT_SIMPLE)){
            return aMistake.correctMoves.reduce(function(prev, current) {
                return (prev.score < current.score) ? prev : current
            }).moveUci;
        }
        else{
            return aMistake.correctMoves.reduce(function(prev, current) {
                return (prev.score > current.score) ? prev : current
            }).moveUci;
        }
    }

    getSquareStyle(): CSSProperties {
        return {
            // height: 90,
            // width: 90,
            zIndex: 4,
            alignItems: 'center'
        }
    }

    getChessBoard() {
        return (
            <div id='boardContainer' style={{display: 'inline-block'}}>
                        <Chessboard 
                            position={this.state.currentBoardFen} 
                            onPieceDrop={this.onPieceDrop} 
                            boardOrientation={this.getCurrentBoardColour() as "white" | "black"} 
                            customSquareStyles={this.getCustomSquareStyles()} 
                            boardWidth={this.getBoardWidth()} 
                            onPieceDragBegin={this.onPieceDragBegin}
                            customArrows={this.state.currentArrows}/>
                    </div>
        );
    }

    getBoardWidth() {
        return Math.min(Math.floor(this.state.windowDimensions.width*0.4), Math.floor(this.state.windowDimensions.height*0.75))
    }

    render() {

        const topPlayer = this.getCurrentBoardColour() === "black" ?  this.getCurrentMistakeOrDefault().fromGame.whitePlayer : this.getCurrentMistakeOrDefault().fromGame.blackPlayer;
        const bottomPlayer = this.getCurrentBoardColour() === "black" ?  this.getCurrentMistakeOrDefault().fromGame.blackPlayer : this.getCurrentMistakeOrDefault().fromGame.whitePlayer;

        return (
            <div id='wholeTrainerContainer' style={{position: 'absolute', width: '80%', height: '80%', margin: 'auto', marginTop: '20px'}}>
                <Grid columns={3}>

                    <Grid.Row>
                        <Grid.Column style={{width: '25%', height: '10px' }}></Grid.Column>
                        <Grid.Column style={{width: this.getBoardWidth()+34, height: '10px', paddingLeft: '15px', paddingRight: '20px'}}>
                            <PlayerName player={topPlayer}></PlayerName>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{paddingTop: '0px', paddingBottom: '0px'}}>
                        
                        <Grid.Column style={{width: '23%', height: this.getBoardWidth() }}>
                            <MistakeComponent mistake={this.getCurrentMistake()}></MistakeComponent>
                        </Grid.Column>

                        <Grid.Column style={{width: '22px', height: this.getBoardWidth() }}>
                            <EvalBar inputEval={this.getCurrentMistakeOrDefault().lastBoardScore} height={this.getBoardWidth()}></EvalBar>
                        </Grid.Column>

                        <Grid.Column style={{width: this.getBoardWidth()}}>
                            {/* https://www.npmjs.com/package/react-chessboard */}
                            {/* style={{float: 'right', height: '75%', position: 'absolute'}} */}
                            {this.getChessBoard()}
                        </Grid.Column>

                        <Grid.Column style={{width: '25%', height: this.getBoardWidth() }}>
                            <ScoreBoard></ScoreBoard>
                        </Grid.Column>

                    </Grid.Row>

                    <Grid.Row style={{paddingTop: '0px', paddingBottom: '0px'}}>
                        <Grid.Column style={{width: '25%', height: '10px'}}></Grid.Column>

                        <Grid.Column style={{width: this.getBoardWidth()+34, paddingTop: '0px', paddingBottom: '0px', paddingLeft: '15px', paddingRight: '20px'}}>
                            <div id='name'>
                                <PlayerName player={bottomPlayer}></PlayerName>
                            </div>
                        </Grid.Column>

                    </Grid.Row>
                    <Grid.Row style={{paddingTop: '0px', paddingBottom: '0px'}}>
                        <Grid.Column style={{width: '25%'}}></Grid.Column>

                        <Grid.Column style={{width: this.getBoardWidth()+30}}>
                            <div id='PuzzleControlsContainer'>
                                <PuzzleControlsComponent boardWidth={this.getBoardWidth()} setHint={this.handleHintButtonClick} handleBackPuzzleClicked={this.handleBackPuzzleClicked} handleNextPuzzleClicked={this.handleNextPuzzleClicked} ></PuzzleControlsComponent>
                            </div>
                        </Grid.Column>

                        <Grid.Column style={{width: '25%'}}></Grid.Column>
                    </Grid.Row>
                    
                </Grid>

                {this.getTransitionModal()}
                {this.getCurrentMistake() ? this.getToolTipContainer() : null}

            </div>

        )

    }

    getToolTipContainer() {
        return (
            <div id='ToolTipContainer'>
                <HelpTipComponent puzzleStage={this.state.puzzleStage} currentPuzzleNumber={this.state.currentPuzzleNumber} ></HelpTipComponent>
            </div>
        )
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
      setPuzzles: (evals: any[]) => dispatch({type: 'SETPUZZLES', payload: evals}),
      setMistakes: (mistakes: any[]) => dispatch({type: 'SETMISTAKES', payload: mistakes}),
      setTrainingState: (trainingState: TrainingState) => dispatch({type: 'SET_TRAINING_STATE', payload: trainingState}),
      setPuzzleResult: (puzzleResult: PuzzleResult) => dispatch({type: 'SET_PUZZLE_RESULT', payload: puzzleResult}),
      setTrainerComplete: () => dispatch({type: 'SET_TRAINING_COMPLETE', payload: {isTrainingComplete: true}})
  }
};

function mapStateToProps(state: any) {
  return { puzzles: state.puzzles, user: state.user, opponentPuzzles: state.opponentPuzzles, restartTraining: state.restartTraining }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChessBoardDiv)