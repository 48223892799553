
export enum TrainingState {
    IDLE = 0,
    ANALYZING_GAMES = 1,
    ANALYZING_MISTAKES = 2,
    GENERATING_PUZZLES = 3,
    TRAINING = 4
}

const initialState: number = 0;

const trainingStateReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "SET_TRAINING_STATE":
        return action.payload as TrainingState;
      default:
        return state;
    }
};
  
  export default trainingStateReducer;