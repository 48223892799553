import { GameData } from '../api/ChessWebsiteGameFetchers/GamesFetcher';
import { getDefaultPlayer, Player } from '../shared_objects/Player';

export interface PositionEval {
    position: string;
    eval: number;
    myMove: string;
    moveNumber: number;
  }
    
  export interface PositionEvaluation {
    fen: string;
    eval: number;
  }
  
  export interface PositionReturn {
    position: string,
    moveUci: string
  }

  export interface GameEvaluations {
    positionEvals: PositionEval[],
    pgnData: GameData,
  }

  export interface MistakeObject {
    lastBoardFEN: string;
    currentBoardFEN: string;
    scoreChange: string;
    move: string;
    myColour: string;
    lastBoardScore: number;
    currentBoardScore: number;
    fromGame: GameData;
    correctMove?: string;
}

export interface MistakeObjectComplete {
  scoreChange: number;
  move: string;
  lastBoardFEN: string;
  correctMoves: CorrectMove[];
  myColour: string;
  fromGame: GameData;
  lastBoardScore: number;
  currentBoardScore: number;
}

export function getDefaultMistakeObjectComplete(): MistakeObjectComplete {
  return {
    scoreChange: 0,
    move: "",
    lastBoardFEN: "",
    correctMoves: [],
    myColour: "white",
    fromGame: {
        pgn: "",
        timeControl: "",
        rules: "",
        whitePlayer: getDefaultPlayer(),
        blackPlayer: getDefaultPlayer(),
        url: "",
    },
    lastBoardScore: 0,
    currentBoardScore: 0,
  }
}

export interface CorrectMove {
  moveUci: string,
  howCorrect: string,
  score: number,
  ponderMove: string,
  nextMoves?: CorrectMove[]
}