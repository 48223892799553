import { connect } from 'react-redux';
import { Dispatch } from "redux";
import React from 'react';
import { Progress, Label, Modal, Header, Icon, Loader, Grid, Dimmer } from 'semantic-ui-react'
import { TrainingState } from '../../reducers/trainingStateReducer';
import './LoadingModal.css';

interface AnalysisLoadingBarState {
  analysisPercentage: number;
  analysisOutOf: number;
  trainingState: TrainingState;
}

enum LoadingStage {
  NONE = 0,
  FETCHING_GAMES = 1,
  ANALYZING_GAMES = 2,
  EVALUATING_MISTAKES = 3,
  GENERATING_PUZZLES = 4,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ListContainerProps = StateProps & DispatchProps

class AnalysisLoadingBar extends React.Component<ListContainerProps, AnalysisLoadingBarState> {

    private static readonly trainingStateMap: Map<TrainingState, LoadingStage> = new Map([
      [TrainingState.ANALYZING_GAMES, LoadingStage.ANALYZING_GAMES],
      [TrainingState.ANALYZING_MISTAKES, LoadingStage.EVALUATING_MISTAKES],
      [TrainingState.GENERATING_PUZZLES, LoadingStage.GENERATING_PUZZLES]
    ]);

    private static readonly completedLoadingStagesMap: Map<TrainingState, LoadingStage[]>  = new Map([
      [TrainingState.ANALYZING_GAMES, [LoadingStage.FETCHING_GAMES]],
      [TrainingState.ANALYZING_MISTAKES, [LoadingStage.FETCHING_GAMES, LoadingStage.ANALYZING_GAMES]],
      [TrainingState.GENERATING_PUZZLES, [LoadingStage.FETCHING_GAMES, LoadingStage.ANALYZING_GAMES, LoadingStage.EVALUATING_MISTAKES ]]
    ]);

    private static readonly wordingMap: Map<LoadingStage, string>  = new Map([
      [LoadingStage.FETCHING_GAMES, "Fetching Games"],
      [LoadingStage.ANALYZING_GAMES, "Analyzing Games"],
      [LoadingStage.EVALUATING_MISTAKES, "Evaluating Mistakes"],
      [LoadingStage.GENERATING_PUZZLES, "Generating Puzzles"]
    ]);

    constructor(props: any) {
        super(props);
    
        this.state = {
          analysisPercentage: 0,
          analysisOutOf: 3,
          trainingState: TrainingState.IDLE
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.props.loadingPercentage !== this.state.analysisPercentage) {
          this.setState({
            analysisPercentage: this.props.loadingPercentage
          })
        }

        if(this.props.trainingState !== this.state.trainingState) {
          this.setState({
            trainingState: this.props.trainingState,
            analysisPercentage: 0
          });
        }
    }

    getLoadingStage(): LoadingStage {
      if(AnalysisLoadingBar.trainingStateMap.has(this.state.trainingState)) {
        return AnalysisLoadingBar.trainingStateMap.get(this.state.trainingState)!;
      }
      return LoadingStage.NONE;
    }

    getCompletedStages(): LoadingStage[] {
      if(AnalysisLoadingBar.completedLoadingStagesMap.has(this.state.trainingState)) {
        return AnalysisLoadingBar.completedLoadingStagesMap.get(this.state.trainingState)!;
      }
      return [];

    }

    getLoaderOrCheckmark(loadingStage: LoadingStage) {
      if(this.getLoadingStage() === loadingStage) {
        console.log("doing workaround for loading stage: ", loadingStage);
        return (<Loader className='workaround' inverted inline></Loader>);
      }
      else if (this.getCompletedStages().includes(loadingStage)) {
        return (<Icon size={'big'} color={'green'} name='checkmark box'></Icon>);
      }

      return;
    }

    render() {
      return (
        <Modal
            className="progressBar"
            // style={{margin: 'auto', justifyContent: 'center', textAlign: 'center'}}
            // basic
            open={AnalysisLoadingBar.trainingStateMap.has(this.state.trainingState)}
            // open={true}
            dimmer='blurring'
        >
          <Header icon='chess knight' content={`Preparing a Chess lesson for ${this.props.user.username}`} ></Header>
          <Modal.Content>
            {this.getGrid()}
          </Modal.Content>

        </Modal>
      )
      
    }

    getGridRow(loadingStage: LoadingStage) {
      return (
        <Grid.Row>
          <Grid.Column style={{width: '10%', height: '20px' }}>
            {this.getLoaderOrCheckmark(loadingStage)}
          </Grid.Column>
          <Grid.Column>
            <h2>{AnalysisLoadingBar.wordingMap.get(loadingStage)!}</h2>
          </Grid.Column>
          <Grid.Column>
            {this.getLoadingStage() === loadingStage && this.getLoadingBar()}
          </Grid.Column>
        </Grid.Row>
      )
    }

    getGrid() {
      return (
        <Grid columns={3}>
            {this.getGridRow(LoadingStage.FETCHING_GAMES)}
            {this.getGridRow(LoadingStage.ANALYZING_GAMES)}
            {this.getGridRow(LoadingStage.EVALUATING_MISTAKES)}
            {this.getGridRow(LoadingStage.GENERATING_PUZZLES)}
        </Grid>
      )
    }


    getLoadingBar() {
      return (<div>
        {/* <Label color='blue' horizontal>{AnalysisLoadingBar.trainingStateMap.get(this.state.trainingState)!}</Label>  */}
        <Progress style={{width:"100%"}} percent={this.state.analysisPercentage} indicating />
      </div>);
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {}
};

function mapStateToProps(state: any) {
  return { loadingPercentage: state.loadingPercentage, trainingState: state.trainingState, user: state.user }
  
}

export default connect(mapStateToProps, mapDispatchToProps)(AnalysisLoadingBar)