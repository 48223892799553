import React from "react";
import { connect } from 'react-redux';
import { Dispatch } from "redux";
//components
import { Button } from "../../components/Button/ButtonElements";
import Navbar from "../../components/Navbar/index";
import ChessTrainer from "../../pages/trainer/ChessTrainer";
//elements
import {
	SignInContainer,
	NavContainer,
	SignInForm,
	SignInHeader,
	InputWrapper,
	EmailInput,
	PasswordInput,
	ButtonWrapper,
	ConfirmPasswordInput,
	LichessInput,
	ChessInput,
	AccountWrapper,
	AccountLabel,
	AccountLink,
    SignUpError,
} from "./SignUpElements";
import UserPool from "../../auth/UserPool";


const win: Window = window;

interface SignUpPageState {
    username: string,
    email: string,
    password: string,
    chessComUsername: string,
    lichessUsername: string,
    errorText?: string,
}

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ListContainerProps = StateProps & DispatchProps

class SignUpPage extends React.Component<ListContainerProps, SignUpPageState> {

    constructor(props: ListContainerProps) {
        super(props);

        this.state = {
            username: '',
            email: '',
            password: '',
            chessComUsername: '',
            lichessUsername: '',
        }

        this.handleEmailChanged = this.handleEmailChanged.bind(this);
		this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
        this.handleChessComValueChanged = this.handleChessComValueChanged.bind(this);
        this.handleLichessValueChanged = this.handleLichessValueChanged.bind(this);
        this.createUniqueUsername = this.createUniqueUsername.bind(this);
        this.getErrorTextDiv = this.getErrorTextDiv.bind(this);
        this.signUp = this.signUp.bind(this);
    }

    signUp(e: any) {
        e.preventDefault();
        console.log("Signing up!");
        UserPool.signUp(this.state.email, this.state.password, [], [], (error, data) => this.handleSignUpResponse(error, data));
    }

    handleSignUpResponse(err: any, data: any) {
        console.log("error "+err);
        if(err) {
            this.setState({
                errorText: err
                
            })
        }
        if(!err) {
            win.location = '/dashboard';
        }
        console.log(err, data);
    }

    createUniqueUsername() {
        if(this.state.chessComUsername.length > 0) {
            return this.state.chessComUsername+"_C"
        }
        if(this.state.lichessUsername.length > 0) {
            return this.state.lichessUsername+"_L"
        }
        throw new Error("chesscom or lichess username must be set");
        
    }

    async handleEmailChanged(e: React.FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;
        this.setState({
            email: newValue
        });
    }

    async handlePasswordChanged(e: React.FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;
        this.setState({
            password: newValue
        });
    }

    async handleChessComValueChanged(e: React.FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;
        this.setState({
            chessComUsername: newValue
        });
    }

    async handleLichessValueChanged(e: React.FormEvent<HTMLInputElement>) {
        const newValue = e.currentTarget.value;
        this.setState({
            lichessUsername: newValue
        });
    }
	

    getErrorTextDiv() {
        return (
            <div>{this.state.errorText!.toString()}</div>
        )
    }

	render() {
		return (
            <>
                <SignInContainer>
                    <NavContainer>
                        <Navbar />
                    </NavContainer>
                    <SignInForm>
                        <SignInHeader>Sign Up</SignInHeader>
                        <InputWrapper>
                            <EmailInput placeholder="Email" value={this.state.email} onChange={this.handleEmailChanged} />
                            <PasswordInput placeholder="Password (6 or more characters)" value={this.state.password} onChange={this.handlePasswordChanged} />
                            <ConfirmPasswordInput placeholder="Confirm Password" />
                            <LichessInput placeholder="Lichess username (optional)" value={this.state.lichessUsername} onChange={this.handleLichessValueChanged} />
                            <ChessInput placeholder="Chess.com username (optional)" value={this.state.chessComUsername} onChange={this.handleChessComValueChanged} />
                        </InputWrapper>
                        <SignUpError>
                            {this.state.errorText ? this.getErrorTextDiv() : null}
                        </SignUpError>
                        <ButtonWrapper>
                            <Button onClick={this.signUp} primary={true} fontBig={false} big={true}>
                                Join now
                            </Button>
                        </ButtonWrapper>
                        <AccountWrapper>
                            <AccountLabel>Already have an account?</AccountLabel>
                            <AccountLink to="/sign-in">Log in.</AccountLink>
                        </AccountWrapper>
                    </SignInForm>
                </SignInContainer>
            </>
        );
	};

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {}
  };
  
  function mapStateToProps(state: any) {
    return { }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage)