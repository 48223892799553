export interface Player {
    username: string,
    rating: number,
}

export function getDefaultPlayer(): Player {
    return {
        username: '',
        rating: 0,
    }
}