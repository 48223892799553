import React from 'react';
import { Player } from '../../shared_objects/Player';
import { Grid } from 'semantic-ui-react';

interface PlayerNameState {
}

interface PlayerNameProps {
    player: Player
}

class PlayerName extends React.Component<PlayerNameProps, PlayerNameState> {

    constructor(props: any) {
        super(props);
        console.log("here");
    }
    render() {
        return (
            <div style={{backgroundColor: 'rgba(70,70,70,.5)', display: 'absolute', paddingTop: '0px', paddingBottom: '0px', width: '100%'}}>
                <Grid columns={3} style={{paddingTop: '0px', marginTop: '0px'}}>
                    <Grid.Column style={{width: '45%', paddingTop: '0px'}}>
                        <h3 style={{color: 'rgb(29, 211, 176)', width: '30%'}}>{this.props.player.username}</h3>
                    </Grid.Column>

                    <Grid.Column style={{width: '45%', paddingTop: '0px'}}> </Grid.Column>

                    <Grid.Column style={{width: '10%', paddingTop: '0px'}}>
                        <h3 style={{color: 'rgb(29, 211, 176)'}}>{this.props.player.rating}</h3>
                    </Grid.Column>
                </Grid>
            </div>
          );
    }

}

export default PlayerName
