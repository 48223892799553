

const initialState: number = 0;

const loadingProgressReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "SET_LOADING_PERCENTAGE":
        return action.payload as number;
      default:
        return state;
    }
};
  
  export default loadingProgressReducer;