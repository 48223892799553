import { MistakeObject } from '../compute/EngineObjects';

const initialState: MistakeObject[] = []

const mistakesReducer = (state = initialState, action: any) => {

    switch(action.type) {
        case "SETMISTAKES":
            return { ...state, mistakes: action.payload as MistakeObject[] };
        default:
            return state;
    }
}

export default mistakesReducer;