import styled from "styled-components";

export const DashboardContainer = styled.div`
	/* background-color: #6373b5; */
	background: #000000;
	width: 100vw;
	height: 100vh;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
`;

export const NavContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 15;
`;

export const DashboardBackground = styled.div`
	height: 100%;
	width: 100%;
	overflow: hidden;
	position: absolute;
`;

export const ImgBG = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	opacity: 60%;
`;

export const DashboardTextWrapper = styled.div`
	z-index: 3;
	max-width: 1200px;
	position: absolute;
	margin-top: 90px;
	padding: 8px 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const DashboardHeader = styled.h1`
	color: #fff;
	font-size: 48px;
	font-family: "Lato", sans-serif;
	font-weight: bold;
	font-style: normal;
	text-align: center;
	letter-spacing: 0.07em;

	/* resizing */
	@media screen and (max-width: 768px) {
		font-size: 40px;
	}
	@media screen and (max-width: 480px) {
		font-size: 32px;
	}
`;

export const DashboardSubHeader = styled.p`
	margin-top: 15px;
	color: #fff;
	font-family: "Lato", sans-serif;
	font-weight: bold;
	font-style: normal;
	font-size: 19px;
	letter-spacing: 0.07em;
	text-align: center;
	max-width: 600px;

	/* resizing */
	@media screen and (max-width: 768px) {
		font-size: 22px;
	}
	@media screen and (max-width: 480px) {
		font-size: 18px;
	}
`;

export const BtnWrapper = styled.div`
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
