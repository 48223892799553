import React, { SyntheticEvent } from "react";
import { PuzzleStage, getAllPuzzleStages } from "../chessboard/ChessBoardDiv";
import { Button, Header, Icon, Modal, Checkbox } from 'semantic-ui-react'

interface HelpTipComponentsProps {
    puzzleStage: PuzzleStage,
    currentPuzzleNumber: number
}

interface HelpTipComponentsState {
    helpTipsHidden: PuzzleStage[];
    showAllToolTips: boolean;
    showAllToolTipsChecked: boolean;
}

class HelpTipComponent extends React.Component<HelpTipComponentsProps, HelpTipComponentsState> {

    constructor(props: HelpTipComponentsProps) {
        super(props);

        this.state = {
            helpTipsHidden: getAllPuzzleStages(),
            showAllToolTips: true,
            showAllToolTipsChecked: false
        }

        this.toggleShowToolTips = this.toggleShowToolTips.bind(this);
        this.closeToolTip = this.closeToolTip.bind(this);
    }

    toggleShowToolTips(e: SyntheticEvent, isChecked: boolean) {
        this.setState({
            showAllToolTipsChecked: isChecked
        });
    }


    closeToolTip(theToolTip: PuzzleStage){

        this.setState({
            helpTipsHidden : this.state.helpTipsHidden.concat(theToolTip)
        });

        if(this.state.showAllToolTipsChecked){
            this.setState({
                showAllToolTips: false
            })
        }
    }

    createToolTipWithText(theHeader: String, theText: string, theToolTip: PuzzleStage){

        return (
            <Modal open={this.state.showAllToolTips}>
            <Header icon='info circle' content={theHeader} />
                <Modal.Content>
                    <p>
                    {theText}
                    </p>
                </Modal.Content>
                <Modal.Actions>
                    <Checkbox label="Don't show tips" onChange={(e: SyntheticEvent, data) => this.toggleShowToolTips(e, data.checked as boolean) }/>
                    <Button color='green' onClick={() => this.closeToolTip(theToolTip)}>
                        <Icon name='play' /> Continue
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }


    getToolTipModal() {

        if(this.props.puzzleStage != null) {
            if(this.props.currentPuzzleNumber === 0){
                if(this.props.puzzleStage === PuzzleStage.OPPONENT_SIMPLE && this.state.helpTipsHidden.indexOf(PuzzleStage.OPPONENT_SIMPLE) === -1){
                    return (
                        this.createToolTipWithText("Mistakes Training Round 1", "This round will attempt to show you why your mistakes are bad. This will put you in the place of your opponents after you made your mistake. Try to capitalize on your own mistakes from your opponents perspective.", this.props.puzzleStage)
                    );
                }

                else if(this.props.puzzleStage === PuzzleStage.PLAYER_SIMPLE && this.state.helpTipsHidden.indexOf(PuzzleStage.PLAYER_SIMPLE) === -1){
                    return (
                        this.createToolTipWithText("Mistakes Training Round 2", "This round you will improve from the mistakes you made shown in the previous round.", this.props.puzzleStage)
                    );
                }
                // else if(this.state.currentTrainingIteration === 1 && this.state.toolTipsShown.indexOf(ToolTip.MyBestMoveRound) === -1){
                //     return (
                //         this.createToolTipWithText("Mistakes Training Round 2", "This round you will improve from the mistakes you made shown in the previous round.", ToolTip.MyBestMoveRound)
                //     );
                // }
                // else if(this.state.currentTrainingIteration === 2 && this.state.toolTipsShown.indexOf(ToolTip.PuzzleMoveRound) === -1){
                //     return (
                //         this.createToolTipWithText("Mistakes Training Round 3", "This round will be a continuation on last round, adding more variations after your move.", ToolTip.PuzzleMoveRound)
                //     );
                // }
            }
        }
    }

    render(): React.ReactNode {
        return (
            <div>
                {this.getToolTipModal()}
            </div>
        )
    }
}


export default HelpTipComponent;