import axios from 'axios';
import {AxiosResponse} from 'axios';
import {StatsData, StatsFetcher} from './StatsFetcher';


export interface ChessComStatData {
    end_time: number;
    white: {
        rating: number,
        result: string,
        username: string;
    };
    black: {
        rating: number,
        result: string,
        username: string;
    };
}

class ChessComStatsFetcher implements StatsFetcher {
    async fetchStats(username: string): Promise<StatsData> {
        const apiAns = await this.getUserArchives(username);
        const gameCall = apiAns.sort().reverse()
        const userInfo = await this.getUserInfo(gameCall)
        const ratings = new Map ([...userInfo.ratings].sort((a,b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()))
        return {ratings}
    }

    async getAxiosResponse<T>(endpointUrl: string): Promise<AxiosResponse> {
        let answer: AxiosResponse;
        try{
            answer = await axios.get<T>(endpointUrl);
        } catch (err){
            throw new Error("unable to query endpointUrl: "+ endpointUrl);
        }

        return answer;
    }

    async getUserArchives(username: string): Promise<string[]> {
        
        const urlEndpoint: string = `https://api.chess.com/pub/player/${username}/games/archives`;


        const answer: AxiosResponse = await this.getAxiosResponse(urlEndpoint);
        
        return answer.data.archives;
    }
    // do the axios call, on this list get latest one eg; https://api.chess.com/pub/player/im_kosta/games/2024/01
    // loop though 5 games it has add it to a dictionary
    async getUserInfo(gameAPI: string[]): Promise<StatsData> {
        let newCount: number = 0;
        const { data } = await this.getAxiosResponse<ChessComStatData[]>(gameAPI[newCount]);
        let diction = this.getRatingsDictionary(data)

        while (diction.size < 90){
            newCount++;
            const { data } = await this.getAxiosResponse<ChessComStatData[]>(gameAPI[newCount]);
            diction = new Map([...diction, ...this.getRatingsDictionary(data)])
        }
        return { ratings: diction };
    }

    getRatingsDictionary(data: { games: string[]}): Map<string, number> {
        const diction = new Map<string, number>();
        data.games.forEach((game: any) => {
            const newTime = new Date(game.end_time * 1000);
            const [timeString, ...restOfString] = newTime.toLocaleString().split(",");
            
            if (!diction.has(timeString)) {
                const rating = game.white.username === "im_kosta" ? game.white.rating : game.black.rating;
                diction.set(timeString, rating);
            }
        });
        return diction;
    }
}


export default ChessComStatsFetcher;