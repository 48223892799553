import React from "react";
import { Button, Icon, Grid } from 'semantic-ui-react'

interface PuzzleControlsComponentProps {
    setHint: Function,
    handleBackPuzzleClicked: Function,
    handleNextPuzzleClicked: Function,
    boardWidth: number
}

interface PuzzleControlsComponentState {
}

class PuzzleControlsComponent extends React.Component<PuzzleControlsComponentProps, PuzzleControlsComponentState> {

    getSquareWidth() {
        return Math.floor((this.props.boardWidth+30)/8)
    }

    render(): React.ReactNode {
        return (
            <Grid columns={5}>
                <Grid.Row stretched={true} className="puzzleControlsRow" style={{width: this.props.boardWidth, marginLeft: '-10px'}}>
                    <Grid.Column style={{width: this.getSquareWidth()*3, textAlign: 'center'}}>
                        <Button icon stretched={true} style={{ width: '99%', position: 'absolute', height: '50px', textAlign: 'center', fontSize: 'large'}} labelPosition='left' onClick={() => this.props.handleBackPuzzleClicked()}>
                            <Icon color='blue' name='chevron circle left' size="large"/>
                            Last Mistake
                        </Button>
                    </Grid.Column>


                    <Grid.Column style={{width: this.getSquareWidth()*2 }}>
                        <Button icon labelPosition='left' onClick={() => this.props.setHint()} style={{position: 'absolute', width: '99%', height: '50px', fontSize: 'large'}}>
                            <Icon color='orange' name='lightbulb outline' size="large" />
                            Hint
                        </Button>
                    </Grid.Column>

                    {/* <Grid.Column style={{width: Math.floor(this.props.boardWidth/8) }}/> */}

                    <Grid.Column style={{width: this.getSquareWidth()*3 }}>
                        <Button stretched={true} icon style={{position: 'absolute', width: '99%', height: '50px', fontSize: 'large'}} labelPosition='right' onClick={() => this.props.handleNextPuzzleClicked()}>
                            <Icon color='blue' name='chevron circle right' size="large"/>
                            Next Mistake
                        </Button>
                    </Grid.Column>

                </Grid.Row>

            </Grid>
        )
    }
}


export default PuzzleControlsComponent;