
export interface TrainingCompleteMessage {
    isTrainingComplete: boolean;
}
const initialState: TrainingCompleteMessage = {
    isTrainingComplete: false
}

const trainingCompleteReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case "SET_TRAINING_COMPLETE":
            return action.payload as TrainingCompleteMessage;
        default:
            return state;
    }
}

export default trainingCompleteReducer;