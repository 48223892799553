import styled from "styled-components";
import { AiOutlineEyeInvisible } from "react-icons/ai";

interface PasswordInputProps {
    isVisible: boolean,
}

export const SignInContainer = styled.div`
	background: linear-gradient(to top, #000 0%, #1dd3b0 100%);
	/* clip-path: polygon(0 0, 100% 0, 100% 42%, 0 82%); */
	/* background: #031926; */
	width: 100vw;
	height: 100vh;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
`;

export const NavContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 55;
`;

export const SignInForm = styled.form`
	background: #fff;
	display: flex;
	flex-direction: column;
	height: 40%;
	width: 40%;
	border-radius: 5px;
	box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.25);
	z-index: 50;

	@media screen and (max-width: 750px) {
		height: 55%;
	}
	@media screen and (max-width: 420px) {
		height: 70%;
		width: 70%;
	}
`;

export const SignInHeader = styled.h1`
	padding-top: 30px;
	font-size: 2.7rem;
	align-items: center;
	align-self: center;
	font-weight: bold;
	color: #000000;
`;

export const InputWrapper = styled.div`
	width: 60%;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
`;

export const EmailInput = styled.input`
	width: 100%;
	padding: 5px 0 5px 1px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 0;
	outline: 0;
	background: transparent;
	border-bottom: 1px solid black;
`;

// export const passwordWrapper = styled.div``;

export const PasswordInput = styled.input<PasswordInputProps>`
	width: 100%;
	padding: 25px 0 5px 1px;
	margin-top: 15px;
	margin-bottom: 15px;
	border: 0;
	outline: 0;
	background: transparent;
	border-bottom: 1px solid black;
	type: text;
	-webkit-text-security: ${({ isVisible }) => (isVisible ? "none" : "disc")};;
	text-security: ${({ isVisible }) => (isVisible ? "none" : "disc")};;
`;

export const VisibilityIcon = styled(AiOutlineEyeInvisible)`
`;


export const CheckboxWrapper = styled.div`
	width: 60%;
	display: inline-flex;
	flex-direction: row;
	margin-left: 20%;
	gap: 10px;

`;

export const Checkbox = styled.input`
	color: #2185d0;
`;

export const KeepMeSignedIn = styled.h2`
	color: #000000;
	font-size: 0.8rem;
	padding-bottom: 22px;
`;

export const BottomWrapper = styled.div`
	width: 60%;
	display: flex;
	margin-top: 2%;
	flex-direction: row;
	align-items: center;
	align-self: center;

	@media screen and (max-width: 1130px) {
		flex-direction: column;
		/* margin-top: 10%; */
	}
`;

export const LinksWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-self: center;
`;

export const ForgotUsername = styled.a`
	color: #2185d0;
	padding: 5px 0 10px 0;
	align-self: left;
	font-size: 0.8rem;
	@media screen and (max-width: 750px) {
		padding-top: 10%;
		align-self: center;
		margin-left: 0;
	}
`;

export const ForgotPassword = styled.a`
	color: #2185d0;
	align-self: left;
	font-size: 0.8rem;
	@media screen and (max-width: 750px) {
		padding-top: 10%;
		align-self: center;
		margin-left: 0;
	}
`;

export const ButtonWrapper = styled.div`
	align-self: right;
	margin-left: 40%;

	@media screen and (max-width: 750px) {
		padding-top: 10%;
		align-self: center;
		margin-left: 0;
	}
	@media screen and (max-width: 1130px) {
		margin-top: 5%;
		align-self: center;
		margin-left: 0;
	}
`;
