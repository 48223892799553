import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { AllPuzzleScores, PuzzleScore, StagePuzzleScores } from './scoreboard/ScoreBoard';
import { Menu, Header, Icon, Divider, Modal, Button } from 'semantic-ui-react';
import React from 'react';
import { PuzzleStage, getAllPuzzleStages } from './chessboard/ChessBoardDiv';
import { PuzzleResult } from '../reducers/puzzleResultReducer';
import { MistakeObjectComplete } from '../compute/EngineObjects';

interface TrainingCompleteState {
    scores: AllPuzzleScores,
    shouldShowCard: boolean
  }

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type TrainingCompleteProps = StateProps & DispatchProps

class TrainingCompleteModal extends React.Component<TrainingCompleteProps, TrainingCompleteState> {

    private puzzles: MistakeObjectComplete[] = [];
    private opponentPuzzles: MistakeObjectComplete[] = [];

    private lastScoreUpdated: MistakeObjectComplete | undefined;

    constructor(props: any) {
        super(props);

        const initialPuzzleScores: AllPuzzleScores = {
            [PuzzleStage.PLAYER_SIMPLE]: [],
            [PuzzleStage.OPPONENT_SIMPLE]: []
        }
    
        this.state = {
          scores: initialPuzzleScores,
          shouldShowCard: false
        }

        this.lastScoreUpdated = undefined;
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {
        if (this.props.puzzles.puzzles != null && this.props.puzzles.puzzles !== this.puzzles && this.props.puzzles.puzzles.length > 0) {
            this.setupScoreMap(this.props.puzzles.puzzles, PuzzleStage.PLAYER_SIMPLE);
            this.puzzles = this.props.puzzles.puzzles;
        }

        if (this.props.isTrainingComplete != null && this.props.isTrainingComplete !== this.state.shouldShowCard) {
            this.setState({
                shouldShowCard: this.props.isTrainingComplete
            })
        }

        if (this.props.opponentPuzzles.opponentPuzzles != null && this.props.opponentPuzzles.opponentPuzzles !== this.opponentPuzzles && this.props.opponentPuzzles.opponentPuzzles.length > 0) {
            this.opponentPuzzles = this.props.opponentPuzzles.opponentPuzzles;
            this.setupScoreMap(this.props.opponentPuzzles.opponentPuzzles, PuzzleStage.OPPONENT_SIMPLE);
        }

        if(this.props.thePuzzleResult.puzzle != null && this.shouldUpdatePuzzleScore(this.props.thePuzzleResult.puzzle)) {
            this.setPuzzleScore(this.props.thePuzzleResult);
        }
    }

    shouldUpdatePuzzleScore(puzzle: MistakeObjectComplete) {
        if (this.lastScoreUpdated == null) {
            return true;
        }
        if (this.props.thePuzzleResult.puzzle.lastBoardFEN !== this.lastScoreUpdated!.lastBoardFEN) {
            return true;
        }
        return false;
    }

    getOverallScoreStr(): string {
        let total: number = 0;
        let counter: number = 0;
        getAllPuzzleStages().forEach(puzzleStage => {
            const mistakesList = this.state.scores[puzzleStage as unknown as PuzzleStage];

            if (mistakesList != null) {
                mistakesList.forEach(puzzleScore => {
                    if(puzzleScore.puzzleScore === PuzzleScore.CORRECT){
                        counter+=1;
                    }
                    total+=1;
                });
            }
        });

        return counter.toString()+"/"+total.toString();
    }


    setPuzzleScore(puzzleResult: PuzzleResult) {

        let currentScoresState: StagePuzzleScores[] = this.state.scores[puzzleResult.puzzleStage];
        const currentScores: StagePuzzleScores[]  = Object.assign([], currentScoresState);

        for (let i = 0; i < currentScores.length; i++) {
            const element = currentScores[i];
            if(element.mistakeObject === puzzleResult.puzzle! && element.puzzleScore === PuzzleScore.NOT_DONE) {
                //this.state.scores[PuzzleStage.PLAYER_SIMPLE][i].puzzleScore = puzzleResult.result;
                currentScores[i].puzzleScore = puzzleResult.result
                break;
            }
        }

        this.lastScoreUpdated = puzzleResult.puzzle;


        this.setState(prevState => ({
            ...prevState,
            scores: {
                ...prevState.scores,
                [puzzleResult.puzzleStage]: currentScores
            }
            //scores[PuzzleStage.PLAYER_SIMPLE]: this.state.scores[PuzzleStage.PLAYER_SIMPLE].map(el => (el.id === id ? Object.assign({}, el, { text }) : el))
            //this.state.data.map(el => (el.id === id ? Object.assign({}, el, { text }) : el))
        }));
        
    }

    restartTraining() {
        this.props.setTrainerComplete();
        this.props.restartTraining();
    }

    setupScoreMap(puzzles: MistakeObjectComplete[], puzzleStage: PuzzleStage) {
        let scoreList: StagePuzzleScores[] = [];

        for (const mistakeObject of puzzles) {
            scoreList.push({mistakeObject, puzzleScore: PuzzleScore.NOT_DONE});
            this.state.scores[puzzleStage].push({mistakeObject, puzzleScore: PuzzleScore.NOT_DONE});
        }
    }


    getPuzzleScores() {

        let allScores: any[] = [];

        let counter: number = 0;
        getAllPuzzleStages().forEach(puzzleStage => {
            const mistakesList = this.state.scores[puzzleStage as unknown as PuzzleStage]
            let returnList: React.ReactNode[] = [];

            if (mistakesList != null) {
                mistakesList.forEach(puzzleScore => {
                    returnList.push(this.getStar(puzzleScore.puzzleScore));
                })
            }
            counter++;
            allScores.push(this.getRoundScores(returnList, "Round "+counter.toString()));
        });

        return allScores;
    }

    getStar(score: PuzzleScore): React.ReactNode {
        if(score === PuzzleScore.NOT_DONE){
            return (
                <Icon name='star outline' size={'big'} />
            )
        }
        else if(score === PuzzleScore.CORRECT){
            return (
                <Icon name='star' size={'big'} color={"green"}/>
            )
        }
        else if(score === PuzzleScore.INCORRECT){
            return (
                <Icon name='star' size={'big'} color={"red"}/>
            )
        }
    }

    getRoundScores(roundScores: React.ReactNode, roundName: String): React.ReactNode {
        return (
            <Menu.Item key={(Math.random() + 1).toString(36)}>
                <Divider horizontal>
                    <Header as='h4' content={`${roundName}`}>
                    </Header>
                </Divider>
                <div className="linkToGame" style={{whiteSpace: 'pre-wrap', textAlign: 'center', margin: 'auto'}}>
                {roundScores}
                </div>
            </Menu.Item>
        );
    }

    render(): React.ReactNode {

        return (
            this.state.shouldShowCard ? this.getTrainingCompleteModal() : null
        );
    }

    handleCloseButtonClick() {
        console.log("handleCloseButtonClick");
        this.props.setTrainerComplete();
        this.setState({
            shouldShowCard: false
        })
    }

    getTrainingCompleteModal() {
        return (
            <Modal closeIcon
                className="trainingCompleteModal"
                header="Training Complete!"
                style={{margin: 'auto', textAlign: 'center'}}
                // basic
                open={this.state.shouldShowCard}
                onClose={() => this.handleCloseButtonClick()}
            >
            <Modal.Content style={{marginLeft: 'auto', marginRight: 'auto'}}>
                {this.getScoreCard()}
            </Modal.Content>

            <Modal.Actions>
                <Button color='green' onClick={() => this.restartTraining()}>
                    <Icon name='redo' /> Restart Training
                </Button>
            </Modal.Actions>

            </Modal>
        )
    }

    getScoreCard() {
        return (
            <Menu vertical secondary style={{paddingTop: '3px', marginLeft: 'auto', marginRight: 'auto'}}>
                <Header as='h1' style={{paddingTop: '10px'}}>
                    You Scored {this.getOverallScoreStr()} !
                </Header>
                

                {this.getPuzzleScores()}

            </Menu>
            );
    }
}


const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        restartTraining: () => dispatch({type: 'RESTART_TRAINING'}),
        setTrainerComplete: () => dispatch({type: 'SET_TRAINING_COMPLETE', payload: {isTrainingComplete: false}})
    }
};

function mapStateToProps(theState: any) {
    return { puzzles: theState.puzzles, thePuzzleResult: theState.thePuzzleResult, opponentPuzzles: theState.opponentPuzzles, isTrainingComplete: theState.trainingComplete.isTrainingComplete }
}

export default connect(mapStateToProps, mapDispatchToProps)(TrainingCompleteModal)