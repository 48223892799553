import { connect } from 'react-redux';
import { Dispatch } from "redux";
import React from 'react';

type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ScoreBoardProps = StateProps & DispatchProps

interface EvalBarState {
    shouldShow: boolean,
    sfEval: number,
    barPercentage: number
}

interface EvalBarProps {
    height: number,
    inputEval: number,
}

class EvalBar extends React.Component<EvalBarProps, EvalBarState> {

    constructor(props: any) {
        super(props);
    
        this.state = {
            shouldShow: false,
            sfEval: 0,
            barPercentage: 50
        }
    }

    getPercentageFromScore(score: number) {
        let outputPercentage: number = 50;
        if (score > 0){
            if (score > 300){
                return 0;
            }
            const percentage = Math.ceil((score/300.0)*100)
            return (100-percentage)/2
        }
        else if (score < 0) {
            if (score < -300){
                return 100;
            }
            const percentage = Math.ceil((score/-300.0)*100)
            return 50+(percentage/2)
        }
        return 50;
    }


    render() {
        const actualScore = this.props.inputEval/100.0;
        const roundedScore = Math.round(actualScore * 10) / 10;
        return (
            <div style={{width: '100%', height: '100%', backgroundColor: 'white', position: 'absolute', border: '4px solid black', borderRadius: '5px'}} className="md:w-10 w-8 h-full mr-1">
                <h3 style={{top: '44%', position: 'absolute', paddingLeft: '1px'}}>{roundedScore}</h3>
                <div style={{ height: `${this.getPercentageFromScore(this.props.inputEval)}%`, backgroundColor: 'rgba(70, 70, 70)'}} >
                </div>
            </div>
          );
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {}
};

function mapStateToProps(theState: any) {
    return { puzzles: theState.puzzles, thePuzzleResult: theState.thePuzzleResult, opponentPuzzles: theState.opponentPuzzles, restartTraining: theState.restartTraining  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EvalBar)
