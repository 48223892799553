import React from "react";
import { MistakeObjectComplete } from "../../compute/EngineObjects";
import {Icon, Menu, Header, Label, Divider} from 'semantic-ui-react'


interface MistakeComponentProps {
    mistake: MistakeObjectComplete | undefined;
}

interface MistakeComponentState {
}


class MistakeComponent extends React.Component <MistakeComponentProps, MistakeComponentState> {

    constructor(props: MistakeComponentProps) {
        super(props);
        console.log("");
    }

    render(): React.ReactNode {
        return(
            this.getMistakeStatsCard()
        );
    }

    getMistakeStatsCard() {

        if(this.props.mistake) {
            return this.getMistakeCard(this.props.mistake!);
        }
        return this.getEmptyMistakeCard();
    }

    getEmptyMistakeCard() {
        return (
            <Menu vertical secondary style={{backgroundColor: 'rgba(70,70,70,.5)', height:'100%', border: '4px solid black', borderRadius: '5px', width: '400px', float: 'right', marginRight: '-20px'}}>
                <Menu.Item >
                    <Header as='h1'>
                    <div className="exclamationHeader" style={{whiteSpace: 'pre-wrap', textAlign: 'center', margin: 'auto', marginTop: '40px'}}>
                        <Icon name='exclamation circle' />
                        <Header.Content style={{color: 'wheat'}}>Mistake</Header.Content>
                        </div>
                    </Header>
                </Menu.Item>

                <Menu.Item>
                    <Divider horizontal style={{marginTop: '40px'}}>
                        <Header as='h2' style={{color: 'wheat'}}>
                            Move
                        </Header>
                    </Divider>
                </Menu.Item>
                <Menu.Item>
                    <Divider horizontal>
                            <Header as='h2' style={{marginTop: '20px', color: 'wheat'}}>
                                Score Change
                            </Header>
                    </Divider>
                    <div className="linkToGame" style={{whiteSpace: 'pre-wrap', justifyContent: 'center', textAlign: 'center', margin: 'auto', marginTop: '10px'}}>

                    </div>

                </Menu.Item>
                <Menu.Item>
                    <Divider horizontal>
                        <Header as='h2' style={{marginTop: '20px', color: 'wheat'}}>
                            Game URL
                        </Header>
                    </Divider>
                </Menu.Item>
            </Menu>
        )
    }


    getMistakeCard(thisMistake: MistakeObjectComplete)  {
        return (
            <Menu vertical secondary style={{backgroundColor: 'rgba(70,70,70,.5)', height:'100%', border: '4px solid black', borderRadius: '5px', width: '400px', float: 'right', marginRight: '-20px'}}>
                <Menu.Item >
                    <Header as='h1'>
                    <div className="exclamationHeader" style={{whiteSpace: 'pre-wrap', textAlign: 'center', margin: 'auto', marginTop: '40px'}}>
                        <Icon name='exclamation circle' />
                        <Header.Content style={{color: 'wheat'}}>Mistake</Header.Content>
                        </div>
                    </Header>
                </Menu.Item>

                <Menu.Item>
                    <Divider horizontal style={{marginTop: '40px'}}>
                        <Header as='h2' style={{color: 'wheat'}}>
                            Move
                        </Header>
                    </Divider>
                    <div className="linkToGame" style={{whiteSpace: 'pre-wrap', textAlign: 'center', margin: 'auto'}}>
                            <Label color={'black'} size="huge" >
                                {thisMistake.move} 
                            </Label>
                    </div>
                </Menu.Item>
                <Menu.Item>
                    <Divider horizontal>
                            <Header as='h2' style={{marginTop: '20px', color: 'wheat'}}>
                                Score Change
                            </Header>
                    </Divider>
                    <div className="linkToGame" style={{whiteSpace: 'pre-wrap', justifyContent: 'center', textAlign: 'center', margin: 'auto', marginTop: '10px'}}>

                        <Label color={'black'} size="huge" >
                            {(thisMistake.scoreChange*-1)/100} 
                        </Label>
                    </div>

                </Menu.Item>
                <Menu.Item>
                    <Divider horizontal>
                        <Header as='h2'  style={{marginTop: '20px', color: 'wheat'}}>
                            Game URL
                        </Header>
                    </Divider>
                    <a href ={thisMistake.fromGame.url} target="_blank" rel="noopener noreferrer">
                    <div className="linkToGame" style={{whiteSpace: 'pre-wrap', justifyContent: 'center', textAlign: 'center', margin: 'auto'}}>
                    <Label as='a' basic color='green' size="large" style={{whiteSpace: 'pre-wrap', justifyContent: 'center'}}>
                        {thisMistake.fromGame.url} 
                    </Label>
                    </div>
                    </a>
                </Menu.Item>
            </Menu>
        )
    }
}

export default MistakeComponent;