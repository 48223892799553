import { CorrectMove,  MistakeObjectComplete, MistakeObject } from './EngineObjects';
import * as ChessJS from "chess.js";
import { Square} from "chess.js";
import * as _ from 'lodash';

export interface MoveMade {
    moveLegal: boolean;
    FEN: string;
}


let thisMistake: CorrectMove[] = [];

let mistakeList: MistakeObjectComplete[];

export async function testMistakes(tempMistakes: MistakeObject[], stockFish: Worker){
    mistakeList = [];
  
    stockFish.postMessage('setoption name MultiPV value 3');
  
    await recursiveCreateMistake(stockFish, tempMistakes, 0, 19);
  }
  
export async function recursiveCreateMistake(worker: Worker, allMistakes: MistakeObject[], counter: number, engineDepth: number){

    if(counter >= allMistakes.length){
        const returnMessage = {
            dataType: "mistakeObject",
            mistakeObjectVals: mistakeList
        }
        window.postMessage(returnMessage, window.location.origin);
        return "done";
    }
    const currentMistake: MistakeObject = allMistakes[counter];

    const pgnString: string = currentMistake.lastBoardFEN;
    worker.postMessage(`position fen ${pgnString}`);
    worker.onmessage = function(event){
        if(event.data.includes(`info depth ${engineDepth}`)){
          let scoreData: string;
          let currentScore: number = -100;
          if(event.data.includes("score mate")){
              scoreData = (event.data.split("score mate")[1]).split(" ")[1];
              currentScore = Number(scoreData);
              if(currentScore < 0){
                  currentScore = -1000;
              }
              else{
                  currentScore = 1000;
              }
          }
          else {
              
              scoreData = (event.data.split("score cp")[1]).split(" ")[1];
              currentScore = Number(scoreData);
              if(currentMistake.myColour === "black"){
                  currentScore = currentScore*-1;
              }
          }
          const theMove = (event.data.split(" pv")[1]).split(" ")[1];
          const ponderMove = (event.data.split(" pv")[1]).split(" ")[2];
          const thisTempMistake: CorrectMove = {
              moveUci: theMove,
              howCorrect: "trash",
              score: currentScore,
              ponderMove: ponderMove
          };
          if(containsObject(thisTempMistake, thisMistake) === false){
              thisMistake.push(thisTempMistake);
          }
        }
        if(event.data.includes("bestmove")){

          console.log("bestMove!: ", event.data, currentMistake)
          mistakeList.push({
              scoreChange: Number(currentMistake.scoreChange),
              move: currentMistake.move,
              lastBoardFEN: currentMistake.lastBoardFEN,
              correctMoves: thisMistake,
              myColour: currentMistake.myColour,
              fromGame: currentMistake.fromGame,
              lastBoardScore: currentMistake.lastBoardScore,
              currentBoardScore: currentMistake.currentBoardScore,
          });
          thisMistake = [];
          const returnMessage = {
              dataType: "progressUpdate",
              updateNum: counter
          }
          window.postMessage(returnMessage, window.location.origin);
          recursiveCreateMistake(worker, allMistakes, counter+1, engineDepth);
          };
        
      }

    worker.postMessage(`go depth ${engineDepth}`);

}

function containsObject(obj: CorrectMove, list: CorrectMove[]) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (JSON.stringify(list[i]) === JSON.stringify(obj)) {
            return true;
        }
    }
  
    return false;
}

export function rankAllBestMoves(incompleteMistakes: MistakeObjectComplete[]): MistakeObjectComplete[]{
  
    let returnMistakes: MistakeObjectComplete[] = [];
  
    for(let i=0;i<incompleteMistakes.length;i++){
      const newMistake: MistakeObjectComplete = rankBestMoves(incompleteMistakes[i]);
      returnMistakes.push(newMistake);
    }
  
  
    return returnMistakes;
}

function getBestScore(allCorrectMoves: CorrectMove[], theColour: string){
    if(theColour === "black"){
      return getMinScore(allCorrectMoves);
    }
    else{
      return getMaxScore(allCorrectMoves);
    }
  }

export function rankBestMoves(aMistake: MistakeObjectComplete){

    const bestScore = getBestScore(aMistake.correctMoves, aMistake.myColour);
  
    for(let i=0;i<aMistake.correctMoves.length;i++){
      aMistake.correctMoves[i].howCorrect = howGoodIsMove(bestScore, aMistake.correctMoves[i].score, aMistake.myColour);
    }
  
    return aMistake;
  
}

function howGoodIsMove(bestMoveScore: number, moveScore: number, colourToMove: string){

    if(colourToMove === "black"){
      bestMoveScore = bestMoveScore*-1;
      moveScore = moveScore*-1;
    }
    //Get how close to best move this move was
    const moveDiff: number = Math.abs(bestMoveScore-moveScore);
    const percentWithin: number = Math.abs((moveDiff/bestMoveScore)*100)
    
    if(bestMoveScore === moveScore){
      return "best";
    }
    else if(percentWithin < 10){
      return "great";
    }
    else if(percentWithin < 30){
      return "good";
    }
    else if(percentWithin < 50){
      return "okay";
    }
    else{
      return "trash";
    }
    
  }

  function getMinScore(theCorrectMoves: CorrectMove[]): number{
  
    let minScore: number = 10000;
    for(let i=0;i<theCorrectMoves.length;i++){
      if(theCorrectMoves[i].score < minScore){
        minScore = theCorrectMoves[i].score
      }
    }
  
    return minScore;
  }
  
  function getMaxScore(theCorrectMoves: CorrectMove[]): number{
    
    let maxScore: number = -10000;
    for(let i=0;i<theCorrectMoves.length;i++){
      if(theCorrectMoves[i].score > maxScore){
        maxScore = theCorrectMoves[i].score
      }
    }
  
    return maxScore;
  }


  export async function GetOpponentBestMoves(worker: Worker, userMistake: MistakeObject, engineDepth: number){

    let myColour: string = "black";

    //inverted the ===
    //i think it should be this way -> why would my colour change based on mistake?
    if(userMistake.myColour !== "black"){
      myColour = "white";
    }
  
    const newPost: MoveMade = makeMoveFront(userMistake.lastBoardFEN, userMistake.move);
    console.log("")
  
    worker.onmessage = function(event){
      const allCorrectMoves: CorrectMove[] = event.data;
      const returnMistakeObject: MistakeObjectComplete = {
        lastBoardFEN: newPost.FEN,
        scoreChange: Number(userMistake.scoreChange),
        move: userMistake.move,
        myColour: myColour,
        correctMoves: allCorrectMoves,
        fromGame: userMistake.fromGame,
        currentBoardScore: userMistake.currentBoardScore,
        lastBoardScore: userMistake.currentBoardScore,
      }
  
      const returnMessage = {
        dataType: "opponentMistakeObject",
        mistakeObjectVals: returnMistakeObject
      }
  
      window.postMessage(returnMessage, window.location.origin);
  
      worker.terminate();
    }
  
    const sendObject = {
      fen: newPost.FEN,
      engineDepth: engineDepth
    }
  
    worker.postMessage(sendObject);
      
}

function getMovesWithScoreName(correctMoves: CorrectMove[], scoreNames: string[]): CorrectMove[]{

	let bestMoves: CorrectMove[] = [];
	for(var i=0;i<correctMoves.length;i++){
    if(scoreNames.indexOf(correctMoves[i].howCorrect) > -1){
			bestMoves.push(correctMoves[i]);
    }
	}

	return bestMoves;
}

  

export async function puzzleCreator(worker: Worker, theMistake: MistakeObjectComplete, engineDepth: number){
    let numIterations: number = 0;
  
      const allBestMoves: CorrectMove[] = getMovesWithScoreName(theMistake.correctMoves, ['best', 'great']);
      if(allBestMoves.length === 1){
  
          let bestMoveObject: CorrectMove = allBestMoves[0];
  
        theMistake.correctMoves = allBestMoves;
          const newPost: MoveMade = makeMoveFront(theMistake.lastBoardFEN, bestMoveObject.moveUci);
          const afterPonderBoard: string = makeMoveFront(newPost.FEN, bestMoveObject.ponderMove).FEN; 
  
         var currentBoardFen: string = afterPonderBoard;
          //analyze position after ponder move
  
          worker.onmessage = function(event){
          numIterations++;
                const allCorrectMoves: CorrectMove[] = event.data;
          const correctMoveNextMoves = rankBestCorrectMoves(allCorrectMoves, theMistake.myColour);
          const tempBestMoves: CorrectMove[] = getMovesWithScoreName(allCorrectMoves, ['best', 'great']);
          
          if(tempBestMoves.length === 1 && numIterations < 5){
            console.log("tempBestMoves", tempBestMoves, theMistake);
            theMistake = updatePuzzleMistake(theMistake, [tempBestMoves[0]], numIterations);
            const tempPost: MoveMade = makeMoveFront(currentBoardFen, tempBestMoves[0].moveUci);
                const tempPonderBoard: string = makeMoveFront(tempPost.FEN, tempBestMoves[0].ponderMove).FEN;
            currentBoardFen = tempPonderBoard;
    
            const tempSendObject = {
              fen: currentBoardFen,
              engineDepth: engineDepth
            }
            worker.postMessage(tempSendObject);
    
          }
          else{
            theMistake = updatePuzzleMistake(theMistake, correctMoveNextMoves, numIterations);
            const returnMessage = {
              dataType: "puzzleMistakeObject",
              mistakeObjectVals: theMistake
            }
    
            window.postMessage(returnMessage, window.location.origin);
    
            worker.terminate();
    
          }
  
  
          }
  
          const sendObject = {
              fen: afterPonderBoard,
              engineDepth: engineDepth
          }
  
          worker.postMessage(sendObject);
    }
    else{
  
      const returnMessage = {
        dataType: "puzzleMistakeObject",
        mistakeObjectVals: theMistake
      }
  
      window.postMessage(returnMessage, window.location.origin);
    }
  }


export function makeMoveFront(fen: string, moveUci: string) : MoveMade{
    const Chess = typeof ChessJS === "function" ? ChessJS : ChessJS.Chess; // For VS code intellisence to work
    const game = new Chess(fen);
    const fromSquare: Square = (moveUci.substring(0,2)) as Square;
    const movingtoSquare = moveUci.substring(2, 4)  as Square;


    const moveObject = game.move({from: fromSquare, to: movingtoSquare });
    if(moveObject === null){
        return {
            moveLegal: false,
            FEN: fen
        }
    }

    return {
        moveLegal: true,
        FEN: game.fen()
    }
}   


function updatePuzzleMistake(theMistake: MistakeObjectComplete, newCorrectMoves: CorrectMove[], numIterations: number){
    let lodashStr: string = "correctMoves";
  
    for(var i = 0; i<numIterations;i++){
      lodashStr = lodashStr+"[0].nextMoves";
    }
  
    _.set(theMistake, lodashStr, newCorrectMoves);
  
    return theMistake
  }
  

  export function rankBestCorrectMoves(allCorrectMoves: CorrectMove[], theColour: string):CorrectMove[]{

    const bestScore = getBestScore(allCorrectMoves, theColour);
  
    for(let i=0;i<allCorrectMoves.length;i++){
      allCorrectMoves[i].howCorrect = howGoodIsMove(bestScore, allCorrectMoves[i].score, theColour);
    }
  
    return allCorrectMoves;
  
  }