import { connect } from 'react-redux';
import { Dispatch } from "redux";
import React from 'react';
import { testMistakes, rankAllBestMoves, rankBestMoves, GetOpponentBestMoves, puzzleCreator } from './puzzleFunctions';
import { MistakeObjectComplete, MistakeObject } from './EngineObjects';
import { TrainingState } from '../reducers/trainingStateReducer';
import { isEventType } from './WorkerHelperFunctions';


const PUZZLE_CREATOR_DEPTH: number = 19;

interface PuzzleCreatorState {
    completeMistakeObjects: any[];
    opponentMistakeObjects: any[];
    puzzleMistakeObjects: any[];
    analyzeProgressPercent: number;
    mistakesAnalyzed: number;
}
  
type StateProps = ReturnType<typeof mapStateToProps>
type DispatchProps = ReturnType<typeof mapDispatchToProps>
type ListContainerProps = StateProps & DispatchProps
  
class PuzzleCreator extends React.Component<ListContainerProps, PuzzleCreatorState> {

    mistakes: any[];
    hasEventListener: boolean;

    constructor(props: any) {
        super(props);
        this.mistakes = [];
        this.hasEventListener = false;

        this.state = {
            completeMistakeObjects: [],
            opponentMistakeObjects: [],
            puzzleMistakeObjects: [],
            analyzeProgressPercent: 0,
            mistakesAnalyzed: 0
        }
    }

    componentDidUpdate(prevProps: any, prevState: any, snapshot: any) {

        if (this.props.mistakes.mistakes != null && this.props.mistakes.mistakes !== this.mistakes && this.props.mistakes.mistakes.length > 0) {

            this.mistakes = this.props.mistakes.mistakes;
            if (this.hasEventListener === false) {
                this.addEventListener(this.mistakes);
                this.hasEventListener = true;
                this.props.setTrainingState(TrainingState.GENERATING_PUZZLES);
            }
        }
        else if (this.props.mistakes.mistakes != null && this.props.mistakes.mistakes.length === 0) {
            console.log("No mistakes!");
        }
    }

    addEventListener(tempMistakeObjects: MistakeObject[]) {

        const stockFishWorker: Worker = new Worker('/stockfish.js');

        var handleWorkerResponse = (event: MessageEvent) => {
            if(isEventType(event, "mistakeObject")){
                const allMistakeVals: MistakeObjectComplete[] = rankAllBestMoves(event.data.mistakeObjectVals);
                this.setState({completeMistakeObjects: allMistakeVals});
                stockFishWorker.terminate();
                //this.findPuzzlesInMistakes(allMistakeVals);
                this.getOpponentsBestMoveInMistakes(tempMistakeObjects);
            }
            else if(isEventType(event, "progressUpdate")) {
                const percentageAnalyzed = ((event.data.updateNum)+1/tempMistakeObjects.length);
                this.setState({
                    mistakesAnalyzed: this.state.mistakesAnalyzed+1,
                    analyzeProgressPercent: Number(percentageAnalyzed*100)/2
                })
            }
        }

        window.addEventListener("message", handleWorkerResponse, false);
        testMistakes(tempMistakeObjects, stockFishWorker);
    }

    async getOpponentsBestMoveInMistakes(theseMistakeObjects: MistakeObject[]){

        let mistakeCounter: number = 0;

        var handleWorkerResponse = (event: MessageEvent) => {
            if(isEventType(event, "opponentMistakeObject")) {
                this.state.opponentMistakeObjects.push(rankBestMoves(event.data.mistakeObjectVals));
                const percentageAnalyzed = ((mistakeCounter+1+theseMistakeObjects.length)/(theseMistakeObjects.length*2))*100;
                this.props.setLoadingPercentage(percentageAnalyzed);
                console.log("getOpponentsBestMoveInMistakes percentage: ", percentageAnalyzed);
                this.setState({
                    mistakesAnalyzed: this.state.mistakesAnalyzed+1,
                    analyzeProgressPercent: Number(percentageAnalyzed*100)
                });
                mistakeCounter++;

                if(this.state.opponentMistakeObjects.length >= theseMistakeObjects.length){
                    console.log("finished getting opponent mistakes: ", this.state.opponentMistakeObjects);
                    this.findPuzzlesInMistakes(this.state.completeMistakeObjects);
                }
                else{
                    GetOpponentBestMoves(new Worker('/stockfish-mv-worker.js'), theseMistakeObjects[mistakeCounter], PUZZLE_CREATOR_DEPTH);
                }
               
            }
        }

        window.removeEventListener("message", handleWorkerResponse, false);
        window.addEventListener("message", handleWorkerResponse, false);

        GetOpponentBestMoves(new Worker('/stockfish-mv-worker.js'), theseMistakeObjects[0], PUZZLE_CREATOR_DEPTH);
    }

    async findPuzzlesInMistakes(theseMistakeObjects: MistakeObjectComplete[]){
        console.log("finding puzzles in mistakes");

        let mistakeCounter: number = 0;

        var handleWorkerResponse = (event: MessageEvent) => {
            if(isEventType(event, "puzzleMistakeObject")) {
                this.state.puzzleMistakeObjects.push(event.data.mistakeObjectVals);
                const percentageAnalyzed = ((mistakeCounter+1)/theseMistakeObjects.length*2)*100;
                console.log("findPuzzlesInMistakes percentage: ", percentageAnalyzed);
                this.props.setLoadingPercentage(percentageAnalyzed);
                this.setState({
                    mistakesAnalyzed: this.state.mistakesAnalyzed+1,
                    analyzeProgressPercent: Number(percentageAnalyzed)
                });
                mistakeCounter++;
                console.log("finished puzzle no: ", mistakeCounter);

                if(this.state.puzzleMistakeObjects.length >= theseMistakeObjects.length){
                    this.setPuzzles();
                }
                else{
                    puzzleCreator(new Worker('/stockfish-mv-worker.js'), theseMistakeObjects[mistakeCounter], PUZZLE_CREATOR_DEPTH);
                }

            }
        }

        window.removeEventListener("message", handleWorkerResponse, false);
        window.addEventListener("message", handleWorkerResponse, false);

        puzzleCreator(new Worker('/stockfish-mv-worker.js'), theseMistakeObjects[0], PUZZLE_CREATOR_DEPTH);


    }

    render() {
        return (null);
    }

    setPuzzles(){
        console.log("I'm finished: setting puzzles now");
        this.props.setPuzzles(this.state.puzzleMistakeObjects);
        this.props.setOpponentPuzzles(this.state.opponentMistakeObjects);
    }

}

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
      setPuzzles: (evals: any[]) => dispatch({type: 'SETPUZZLES', payload: evals}),
      setOpponentPuzzles: (evals: any[]) => dispatch({type: 'SET_OPPONENT_PUZZLE', payload: evals}),
      setLoadingPercentage: (percentageAnalyzed: number) => dispatch({type: 'SET_LOADING_PERCENTAGE', payload: percentageAnalyzed}) ,
      setTrainingState: (trainingState: TrainingState) => dispatch({type: 'SET_TRAINING_STATE', payload: trainingState})
  }
};

function mapStateToProps(state: any) {
  return { mistakes: state.mistakes, user: state.user }
}

export default connect(mapStateToProps, mapDispatchToProps)(PuzzleCreator)