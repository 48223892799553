
import { MistakeObject } from '../compute/EngineObjects';

const initialState: MistakeObject[] = []

const opponentPuzzlesReducer = (state = initialState, action: any) => {

    switch(action.type) {
        case "SET_OPPONENT_PUZZLE":
            return { ...state, opponentPuzzles: action.payload };
        default:
            return state;
    }
}

export default opponentPuzzlesReducer;