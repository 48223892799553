import React, { useRef } from "react";
//components
// import { Button } from "../Button/ButtonElements";

//assets
import { LogOutButton, SignInButton, SignUpButton } from "../../components/Button/ButtonElements";
import logo from "../../images/icons/bigBlueIcon.png";
import { Nav, NavLink, MenuBars, NavMenu } from "./NavbarElements";
import UserPool from "../../auth/UserPool";
import { anyTypeAnnotation } from "@babel/types";
import { useLinkClickHandler } from "react-router-dom";
import { CognitoUser } from "amazon-cognito-identity-js";
const win: Window = window;

function handleClick(user: CognitoUser | null){
	user ? user.signOut(): console.log("error")
	win.location = '/dashboard';
};

function handleSignIn() {
	win.location = '/sign-in';
}
function handleSignUp() {
	win.location = '/sign-up';
}

const Navbar = () => {
	const signedUser = UserPool.getCurrentUser();
	return (
		<Nav>
			<NavLink to="/">
				<img src={logo} alt="logo" width="70" height="70" />
			</NavLink>
			<MenuBars />
			<NavMenu>
			{signedUser ? <LogOutButton onClick={() => handleClick(signedUser)} primary={true} fontBig={false} big={true}>Log out</LogOutButton> : null }
			{signedUser ? null : <SignInButton onClick={() => handleSignIn()} primary={true} fontBig={false} big={true}>Sign in</SignInButton> }
			{signedUser ? null : <SignUpButton onClick={() => handleSignUp()} primary={true} fontBig={false} big={true}>Sign Up</SignUpButton> }

				{/* <NavButton>
					<Button to="/sign-in" primary={true} fontBig={false} big={false}>
						Sign in
					</Button>
				</NavButton>
				<NavButton>
					<Button to="/sign-up" primary={true} fontBig={false} big={false}>
						Sign up
					</Button>
				</NavButton> */}
			</NavMenu>
		</Nav>
	);
};

export default Navbar;
