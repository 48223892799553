import { PuzzleStage } from "../components/chessboard/ChessBoardDiv";
import { PuzzleScore } from "../components/scoreboard/ScoreBoard";
import { MistakeObjectComplete } from "../compute/EngineObjects";


export interface PuzzleResult {
  puzzle?: MistakeObjectComplete,
  result: PuzzleScore;
  puzzleStage: PuzzleStage
}
const initialState: PuzzleResult = {
    puzzle: undefined,
    result: PuzzleScore.NOT_DONE,
    puzzleStage: PuzzleStage.PLAYER_SIMPLE
};

const puzzleResultReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case "SET_PUZZLE_RESULT":
        return action.payload as PuzzleResult
        //return {...state, puzzleResult: action.payload as PuzzleResult};
      default:
        return state;
    }
  };
  
  export default puzzleResultReducer;