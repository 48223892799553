import axios from 'axios';
import {GameData, GamesFetcher} from './GamesFetcher';
import { getDefaultPlayer } from '../../shared_objects/Player';


class LichessGamesFetcher implements GamesFetcher {


    async fetchGames(username: string, maxGames: number): Promise<GameData[]> {
        return this.getLastLichessGames(username, maxGames, ['bullet','blitz','rapid','classical']);
    }
    
    async getLastLichessGames(username: string, numOfGames: number, variants: string[]) : Promise<GameData[]>{

        const apiAns = await this.getLichessGames(username, numOfGames, variants);
    
        const splitArray = (apiAns.split(/\r?\n\n/)).slice(0, -1);
    
        let returnGames: GameData[] = [];
    
        for(let i=1;i< splitArray.length;i=i+2){
    
            returnGames.push(this.createSinglePgn(splitArray.slice(i-1, i+1)));
        }
    
        return returnGames;
    }


    async getLichessGames(username: string, maxGames: number, variants: string[]) : Promise<string> {

        let urlEndpoint: string = `https://lichess.org/api/games/user/${username}?max=${maxGames}&perfType=`;
    
        for(var i=0;i<variants.length-1;i++){
            urlEndpoint = urlEndpoint+variants[i]+",";
        }
        urlEndpoint = urlEndpoint+variants[variants.length-1];
    
        const answer = await (axios.get(urlEndpoint));
    
        const gamesAnswer = answer["data"];
    
        return gamesAnswer;
    }

    createSinglePgn(inputLists: string[]): GameData{
        let currentGame: GameData = {
            pgn: "",
            timeControl: "",
            rules: "",
            whitePlayer: getDefaultPlayer(),
            blackPlayer: getDefaultPlayer(),
            url: ""
        }
    
        currentGame.pgn = inputLists[1];
        currentGame.timeControl = this.getAttribute(inputLists[0], "TimeControl");
        currentGame.rules = this.getAttribute(inputLists[0], "Variant");
        currentGame.whitePlayer = {username: this.getAttribute(inputLists[0], "White"), rating: 0};
        currentGame.blackPlayer = {username: this.getAttribute(inputLists[0], "Black"), rating: 0};
        currentGame.url = this.getAttribute(inputLists[0], "Site");
    
        return currentGame;
    
    }

    getAttribute(inputStr: string, attrName: string):string {

        const splitStr = inputStr.split(attrName)[1];
        const attrVal = splitStr.split('"')[1];
    
        return attrVal;
    
    }

}


export default LichessGamesFetcher;