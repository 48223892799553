import {v4 as uuidv4} from 'uuid';

export const restartTrainingInitialState: string = "123";

const restartTrainingReducer = (state = restartTrainingInitialState, action: any) => {
    switch(action.type) {
        case "RESTART_TRAINING":
            return uuidv4();
        default:
            return state;
    }
}

export default restartTrainingReducer;