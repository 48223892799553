import { MistakeObject } from '../compute/EngineObjects';

const initialState: MistakeObject[] = []

const puzzlesReducer = (state = initialState, action: any) => {

    switch(action.type) {
        case "SETPUZZLES":
            return { ...state, puzzles: action.payload };
        default:
            return state;
    }
}

export default puzzlesReducer;