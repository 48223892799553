import { Square } from 'chess.js';
import {GameData} from '../api/ChessWebsiteGameFetchers/GamesFetcher';

import { PositionEval, PositionReturn } from './EngineObjects';

var kokopu = require('kokopu');

  

let positionHashes: Map<string, string> = new Map<string, string>(); 

export function resetPositionsEvals(){
  positionHashes.clear();
}

export async function analyzeGame(worker: Worker, pgnGame: GameData, moveCounter: number, moveLimit: number, engineDepth: number, useStatic: boolean, threadNumber: number){
  let evalNumbers:PositionEval[] = [];
  if(useStatic === false){
    console.log("Analyzing Game:");
    console.log(pgnGame);
    await recursiveAnalyze(worker, pgnGame, moveCounter, moveLimit, engineDepth, evalNumbers, threadNumber);
  }
}


export async function recursiveAnalyze(worker: Worker, pgnGame: GameData, moveCounter: number, moveLimit: number, engineDepth: number, evalNumbers: PositionEval[], thisThreadID: number){
  const pgnPos: PositionReturn = getPositionAtI(pgnGame, moveCounter);
  const pgnString = pgnPos.position;
  if(pgnString === "none" || moveCounter >= moveLimit){
    const returnMessage = {
      dataType: "pgnEval",
      evalNumbers: positionHashes
    }
    window.postMessage(returnMessage, window.location.origin);
    worker.terminate();
    return null;
  }
  worker.onmessage = function(event){
    positionHashes.set(event.data.fen, event.data.evalScore);

    recursiveAnalyze(worker, pgnGame, moveCounter+1, moveLimit, engineDepth, evalNumbers, thisThreadID);
  }

  const sendObject = {
    fen: pgnString,
    engineDepth: engineDepth
  }

  if(positionHashes.has(pgnString) === false){
    worker.postMessage(sendObject);
  }
  else{
    recursiveAnalyze(worker, pgnGame, moveCounter+1, moveLimit, engineDepth, evalNumbers, thisThreadID);
  }
}

export function getPositionAtI(pgnGame: GameData, counter: number): PositionReturn {

    //const pgn = "1." +pgnGame["pgn"].split("1.")

    var database = kokopu.pgnRead(pgnGame["pgn"]);
    var theGame = database.game(0);
    if(counter === 0){
      const fromSquare: Square = theGame.mainVariation().first()._data.moveDescriptor.from() as Square;
      const toSquare: Square = theGame.mainVariation().first()._data.moveDescriptor.to() as Square;
      return {
        position: "rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1", 
        moveUci: `${fromSquare}${toSquare}`
      };
    }
    let theNum: number = 1;
    for(var node = theGame.mainVariation().first(); node; node = node.next()){
      if(theNum === counter){
        if(node.next() != undefined){
          const fromSquare: Square = node.next()._data.moveDescriptor.from() as Square;
          const toSquare: Square = node.next()._data.moveDescriptor.to() as Square;
          return {
            position: node.position().fen(),
            moveUci: `${fromSquare}${toSquare}`
          }
        }
      }
      theNum = theNum+1;
    }
  
    return ({
      position: "none",
      moveUci: "none"
    });
}
  